import React, { useState, useEffect } from 'react';
import { ArrowLeft, CheckCircle, Clock, Award, Play, Lock } from 'lucide-react';
import { Course } from './types';
import { generateCertificate } from '../../../services/certificateGenerator';
import { useAuth } from '../../../hooks/useAuth';
import { useDashboardStore } from '../../../store/dashboardStore';

interface CourseViewProps {
  course: Course;
  onBack: () => void;
}

export function CourseView({ course, onBack }: CourseViewProps) {
  const [currentModule, setCurrentModule] = useState(0);
  const [completedModules, setCompletedModules] = useState<string[]>([]);
  const { user } = useAuth();
  const addActivity = useDashboardStore((state) => state.addActivity);
  const updateCourseProgress = useDashboardStore((state) => state.updateCourseProgress);

  const handleModuleComplete = async (moduleId: string) => {
    try {
      const newCompletedModules = [...completedModules, moduleId];
      setCompletedModules(newCompletedModules);

      const progress = Math.round((newCompletedModules.length / course.modules.length) * 100);
      updateCourseProgress(course.id, progress);

      addActivity({
        type: 'course',
        title: `Completou o módulo: ${course.modules[currentModule].title}`,
      });

      if (progress === 100) {
        await handleCourseComplete();
      }
    } catch (error) {
      console.error('Error completing module:', error);
      alert('Erro ao salvar progresso. Por favor, tente novamente.');
    }
  };

  const handleCourseComplete = async () => {
    if (!user) return;

    try {
      await generateCertificate({
        studentName: user.name,
        courseName: course.title,
        completionDate: new Date(),
        duration: course.duration
      });

      addActivity({
        type: 'course',
        title: `Concluiu o curso: ${course.title}`,
      });

      alert('Parabéns! Você concluiu o curso. Seu certificado foi gerado.');
    } catch (error) {
      console.error('Error completing course:', error);
      alert('Erro ao gerar certificado. Por favor, tente novamente.');
    }
  };

  const calculateProgress = () => {
    return Math.round((completedModules.length / course.modules.length) * 100);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center mb-6">
        <button
          onClick={onBack}
          className="mr-4 p-2 hover:bg-gray-100 rounded-full"
        >
          <ArrowLeft className="h-6 w-6" />
        </button>
        <div>
          <h2 className="text-2xl font-semibold">{course.title}</h2>
          <div className="flex items-center text-gray-600 mt-1">
            <Clock className="h-4 w-4 mr-1" />
            <span>{course.duration}</span>
            <span className="mx-2">•</span>
            <Award className="h-4 w-4 mr-1" />
            <span>{course.level}</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          {/* Video Player */}
          <div className="aspect-video mb-6 bg-gray-100 rounded-lg overflow-hidden">
            <iframe
              src={course.modules[currentModule].videoUrl}
              className="w-full h-full"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>

          {/* Current Module Info */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">
              {course.modules[currentModule].title}
            </h3>
            <p className="text-gray-600">
              {course.modules[currentModule].description}
            </p>
          </div>

          {/* Module Navigation */}
          <div className="flex justify-between">
            <button
              onClick={() => setCurrentModule(prev => Math.max(0, prev - 1))}
              disabled={currentModule === 0}
              className="px-4 py-2 border rounded-lg disabled:opacity-50"
            >
              Módulo Anterior
            </button>
            <button
              onClick={() => handleModuleComplete(course.modules[currentModule].id)}
              disabled={completedModules.includes(course.modules[currentModule].id)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              {completedModules.includes(course.modules[currentModule].id) 
                ? 'Módulo Concluído' 
                : 'Concluir Módulo'
              }
            </button>
            <button
              onClick={() => setCurrentModule(prev => Math.min(course.modules.length - 1, prev + 1))}
              disabled={currentModule === course.modules.length - 1}
              className="px-4 py-2 border rounded-lg disabled:opacity-50"
            >
              Próximo Módulo
            </button>
          </div>
        </div>

        <div className="space-y-6">
          {/* Course Progress */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold">Seu Progresso</h3>
              <span className="text-blue-600 font-medium">{calculateProgress()}%</span>
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-full bg-blue-600 rounded-full transition-all duration-300"
                style={{ width: `${calculateProgress()}%` }}
              />
            </div>
          </div>

          {/* Instructor Info */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold mb-4">Instrutor</h3>
            <div className="flex items-center">
              <img
                src={course.instructor.avatar}
                alt={course.instructor.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="ml-3">
                <p className="font-medium">{course.instructor.name}</p>
                <p className="text-sm text-gray-600">{course.instructor.title}</p>
              </div>
            </div>
          </div>

          {/* Module List */}
          <div>
            <h3 className="font-semibold mb-4">Módulos do Curso</h3>
            <div className="space-y-2">
              {course.modules.map((module, index) => (
                <button
                  key={module.id}
                  onClick={() => setCurrentModule(index)}
                  className={`w-full p-3 rounded-lg text-left flex items-center justify-between ${
                    currentModule === index
                      ? 'bg-blue-50 border border-blue-200'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center">
                    {completedModules.includes(module.id) ? (
                      <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                    ) : (
                      <Play className="h-5 w-5 text-gray-400 mr-2" />
                    )}
                    <div>
                      <p className="font-medium">{module.title}</p>
                      <p className="text-sm text-gray-600">{module.duration}</p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}