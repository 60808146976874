import { supabase } from '../../lib/supabase';

export async function downloadDocument(url: string, filename: string) {
  try {
    // Validate URL
    if (!url) {
      throw new Error('Invalid document URL');
    }

    // Extract file path from URL
    const filePath = url.split('/').slice(-2).join('/');
    
    // Get signed URL for private access
    const { data, error } = await supabase.storage
      .from('documents')
      .createSignedUrl(filePath, 60); // 60 seconds expiry

    if (error) {
      throw new Error('Failed to generate download URL');
    }

    // Download using signed URL
    const response = await fetch(data.signedUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch document');
    }
    
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Cleanup
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading document:', error);
    throw new Error('Erro ao baixar documento. Por favor, tente novamente.');
  }
}