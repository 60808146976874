import { create } from 'zustand';

interface ProfileStore {
  avatar: string | null;
  updateAvatar: (url: string) => void;
}

export const useProfileStore = create<ProfileStore>((set) => ({
  avatar: null,
  updateAvatar: (url) => set({ avatar: url }),
}));