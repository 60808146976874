import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';
import { UserProfile } from '../types/user';

interface AuthState {
  isAuthenticated: boolean;
  user: UserProfile | null;
  login: (email: string, password: string) => Promise<{ error: Error | null }>;
  signup: (email: string, password: string, name: string) => Promise<{ error: Error | null }>;
  logout: () => Promise<void>;
  updateProfile: (updates: Partial<UserProfile>) => Promise<void>;
}

export const useAuth = create<AuthState>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      user: null,

      login: async (email: string, password: string) => {
        try {
          const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
            email,
            password,
          });

          if (authError) throw authError;

          const { data: profile } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', authData.user.id)
            .single();

          set({
            isAuthenticated: true,
            user: profile,
          });

          return { error: null };
        } catch (error) {
          return { error: error as Error };
        }
      },

      signup: async (email: string, password: string, name: string) => {
        try {
          const { data: authData, error: authError } = await supabase.auth.signUp({
            email,
            password,
          });

          if (authError) throw authError;

          const { error: profileError } = await supabase.from('profiles').insert({
            id: authData.user?.id,
            email,
            name,
          });

          if (profileError) throw profileError;

          return { error: null };
        } catch (error) {
          return { error: error as Error };
        }
      },

      logout: async () => {
        await supabase.auth.signOut();
        set({ isAuthenticated: false, user: null });
      },

      updateProfile: async (updates: Partial<UserProfile>) => {
        const { user } = get();
        if (!user?.id) return;

        const { error } = await supabase
          .from('profiles')
          .update(updates)
          .eq('id', user.id);

        if (error) throw error;

        set({ user: { ...user, ...updates } });
      },
    }),
    {
      name: 'auth-storage',
    }
  )
);