import React from 'react';
import { ResumeData } from '../types';

export function ClassicTemplate({ data }: { data: ResumeData }) {
  return (
    <div className="p-8 font-serif bg-white">
      {/* Classic Header with Border */}
      <div className="text-center border-b-2 border-gray-900 pb-6 mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-3">{data.name}</h1>
        <div className="flex justify-center gap-6 text-gray-700">
          {data.email && <span>{data.email}</span>}
          {data.phone && <span>{data.phone}</span>}
        </div>
      </div>

      {/* Summary */}
      {data.summary && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-3 text-center uppercase">
            Perfil Profissional
          </h2>
          <p className="text-gray-700 leading-relaxed text-center max-w-3xl mx-auto">
            {data.summary}
          </p>
        </div>
      )}

      {/* Two Column Layout */}
      <div className="grid grid-cols-[2fr_1px_1fr] gap-8">
        <div>
          {/* Experience */}
          {data.experience.length > 0 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4 uppercase">
                Experiência Profissional
              </h2>
              <div className="space-y-6">
                {data.experience.map((exp, index) => (
                  <div key={index}>
                    <h3 className="font-bold text-lg text-gray-900">{exp.title}</h3>
                    <div className="font-semibold text-gray-700">{exp.company}</div>
                    <div className="text-gray-600 italic mb-2">{exp.period}</div>
                    <p className="text-gray-700">{exp.description}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Vertical Divider */}
        <div className="bg-gray-300"></div>

        {/* Right Column */}
        <div>
          {/* Education */}
          {data.education.length > 0 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4 uppercase">
                Formação
              </h2>
              <div className="space-y-4">
                {data.education.map((edu, index) => (
                  <div key={index}>
                    <h3 className="font-bold text-gray-900">{edu.degree}</h3>
                    <div className="text-gray-700">{edu.institution}</div>
                    <div className="text-gray-600 italic">{edu.period}</div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Skills */}
          {data.skills.length > 0 && (
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4 uppercase">
                Habilidades
              </h2>
              <ul className="list-disc list-inside space-y-2">
                {data.skills.map((skill, index) => (
                  <li key={index} className="text-gray-700">
                    {skill}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}