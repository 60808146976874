import React, { useState, useEffect } from 'react';
import { Plus, Filter } from 'lucide-react';
import { ProjectCard } from './ProjectCard';
import { PortfolioEditor } from './PortfolioEditor';
import { Project } from './types';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../hooks/useAuth';
import { shareProject } from '../../../utils/sharing';

export function PortfolioList() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [filter, setFilter] = useState('all');
  const { user } = useAuth();

  useEffect(() => {
    loadProjects();
  }, [user]);

  const loadProjects = async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('portfolio_projects')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setProjects(data || []);
    } catch (error) {
      console.error('Error loading projects:', error);
    }
  };

  const handleShare = async (project: Project) => {
    try {
      const shareUrl = `${window.location.origin}/portfolio/${project.id}`;
      await shareProject({
        title: project.title,
        text: project.description,
        url: shareUrl,
      });

      // Update share count
      const { error } = await supabase
        .from('portfolio_projects')
        .update({ shares: project.shares + 1 })
        .eq('id', project.id);

      if (error) throw error;
      loadProjects();
    } catch (error) {
      console.error('Error sharing project:', error);
    }
  };

  const filteredProjects = projects.filter(project => 
    filter === 'all' || project.category === filter
  );

  return (
    <div className="space-y-6">
      {isEditing ? (
        <PortfolioEditor
          onSave={() => {
            setIsEditing(false);
            loadProjects();
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">Meu Portfólio</h2>
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
              <span>Novo Projeto</span>
            </button>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md">
            <div className="flex items-center space-x-4">
              <Filter className="h-5 w-5 text-gray-400" />
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="border-0 bg-transparent focus:ring-0"
              >
                <option value="all">Todas as Categorias</option>
                <option value="web">Desenvolvimento Web</option>
                <option value="mobile">Desenvolvimento Mobile</option>
                <option value="desktop">Aplicações Desktop</option>
                <option value="design">Design</option>
                <option value="other">Outros</option>
              </select>
            </div>
          </div>

          {filteredProjects.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  onShare={handleShare}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-white rounded-lg shadow-md">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Nenhum projeto encontrado
              </h3>
              <p className="text-gray-600">
                Comece adicionando seu primeiro projeto ao portfólio
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}