import React from 'react';
import { Building2, MapPin, DollarSign, Clock, ExternalLink } from 'lucide-react';

interface JobCardProps {
  title: string;
  company: string;
  location: string;
  salary: string;
  type: string;
  match: number;
  onApply: () => void;
}

export function JobCard({
  title,
  company,
  location,
  salary,
  type,
  match,
  onApply,
}: JobCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold text-lg">{title}</h3>
          <div className="flex items-center text-gray-600 mt-1">
            <Building2 className="h-4 w-4 mr-1" />
            <span>{company}</span>
          </div>
        </div>
        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
          {match}% match
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex items-center text-gray-600">
          <MapPin className="h-4 w-4 mr-1" />
          <span>{location}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <DollarSign className="h-4 w-4 mr-1" />
          <span>{salary}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <Clock className="h-4 w-4 mr-1" />
          <span>{type}</span>
        </div>
      </div>
      
      <div className="flex space-x-3">
        <button
          onClick={onApply}
          className="flex-1 flex items-center justify-center space-x-2 bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
        >
          <span>Candidatar-se</span>
          <ExternalLink className="h-4 w-4" />
        </button>
        <button className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
          Salvar
        </button>
      </div>
    </div>
  );
}