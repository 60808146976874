import React, { useState, useEffect } from 'react';
import { User, Mail, Phone, MapPin, Briefcase, Globe, Save } from 'lucide-react';
import { UserProfile } from '../../../../types/user';
import { ImageUpload } from '../../../shared/ImageUpload';
import { useProfileStore } from '../../../../store/profileStore';

interface ProfileSectionProps {
  profile: UserProfile;
  onUpdate: (updates: Partial<UserProfile>) => Promise<void>;
}

export function ProfileSection({ profile, onUpdate }: ProfileSectionProps) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(profile);
  const updateAvatar = useProfileStore((state) => state.updateAvatar);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onUpdate(formData);
      // Update global avatar state when profile is updated
      if (formData.avatar) {
        updateAvatar(formData.avatar);
      }
      alert('Perfil atualizado com sucesso!');
    } catch (error) {
      alert('Erro ao atualizar perfil. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (url: string) => {
    setFormData(prev => ({ ...prev, avatar: url }));
    updateAvatar(url);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h3 className="text-lg font-medium">Informações do Perfil</h3>
      
      <div className="flex items-center space-x-6">
        <ImageUpload
          currentImage={formData.avatar}
          onUpload={handleImageUpload}
          className="w-24 h-24 rounded-full"
        />
        <div>
          <h4 className="font-medium">Foto do Perfil</h4>
          <p className="text-sm text-gray-500">
            JPG, GIF ou PNG. Tamanho máximo de 2MB.
          </p>
        </div>
      </div>

      {/* Rest of the form fields */}
      
      <button
        type="submit"
        disabled={loading}
        className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
      >
        {loading ? (
          <>
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            <span>Salvando...</span>
          </>
        ) : (
          <>
            <Save className="h-5 w-5" />
            <span>Salvar Alterações</span>
          </>
        )}
      </button>
    </form>
  );
}