import { Question } from './types';

export const questions: Question[] = [
  {
    id: 1,
    text: "Como você prefere resolver problemas?",
    options: [
      "Analisando dados e informações detalhadamente",
      "Pensando em soluções criativas e inovadoras",
      "Discutindo com outras pessoas para chegar a uma solução",
      "Testando diferentes abordagens na prática"
    ],
    category: "problemSolving"
  },
  {
    id: 2,
    text: "Em um trabalho em grupo, qual papel você geralmente assume?",
    options: [
      "Organizador e planejador",
      "Gerador de ideias",
      "Mediador e comunicador",
      "Executor e implementador"
    ],
    category: "teamwork"
  },
  {
    id: 3,
    text: "O que mais te motiva em um projeto?",
    options: [
      "Descobrir novos conhecimentos e aprender",
      "Expressar ideias e criar algo novo",
      "Ajudar e impactar outras pessoas",
      "Ver resultados concretos do trabalho"
    ],
    category: "motivation"
  },
  {
    id: 4,
    text: "Como você prefere passar seu tempo livre?",
    options: [
      "Lendo e pesquisando sobre assuntos interessantes",
      "Praticando hobbies artísticos ou criativos",
      "Socializando e conhecendo pessoas",
      "Fazendo atividades práticas ou esportes"
    ],
    category: "leisure"
  },
  {
    id: 5,
    text: "Qual ambiente de trabalho mais te agrada?",
    options: [
      "Silencioso e organizado",
      "Dinâmico e flexível",
      "Colaborativo e social",
      "Ativo e variado"
    ],
    category: "environment"
  },
  // Adicione mais questões conforme necessário
];