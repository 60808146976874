import { supabase } from '../../lib/supabase';

export async function uploadFile(userId: string, file: File | Blob, prefix: string = '') {
  const fileExt = file instanceof File ? file.name.split('.').pop() : 'pdf';
  const fileName = `${userId}/${prefix}${Date.now()}-${Math.random().toString(36).substr(2, 9)}.${fileExt}`;

  const { data, error } = await supabase.storage
    .from('documents')
    .upload(fileName, file);

  if (error) throw error;

  const { data: { publicUrl } } = supabase.storage
    .from('documents')
    .getPublicUrl(fileName);

  return publicUrl;
}