import React from 'react';
import { Bot } from 'lucide-react';

export function LoadingIndicator() {
  return (
    <div className="flex items-center space-x-2">
      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
        <Bot className="h-5 w-5 text-blue-600" />
      </div>
      <div className="bg-gray-100 rounded-lg p-3">
        <div className="flex space-x-2">
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100" />
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200" />
        </div>
      </div>
    </div>
  );
}