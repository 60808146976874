import { jsPDF } from 'jspdf';

interface SignatureOptions {
  name: string;
  color?: string;
  size?: number;
}

export async function generateSignature({ name, color = '#1E40AF', size = 48 }: SignatureOptions): Promise<string> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  
  // Set canvas size
  canvas.width = size * 10;
  canvas.height = size * 3;
  
  // Configure text style
  ctx.fillStyle = color;
  ctx.font = `italic ${size}px "Brush Script MT", cursive`;
  ctx.textBaseline = 'middle';
  
  // Add signature
  ctx.fillText(name, 10, canvas.height / 2);
  
  // Add underline
  const textWidth = ctx.measureText(name).width;
  ctx.beginPath();
  ctx.moveTo(10, (canvas.height / 2) + (size / 2));
  ctx.lineTo(textWidth + 20, (canvas.height / 2) + (size / 2));
  ctx.strokeStyle = color;
  ctx.lineWidth = 1;
  ctx.stroke();
  
  return canvas.toDataURL('image/png');
}

export async function addSignatureToDocument(fileUrl: string, signature: string, position = { x: 50, y: 750 }): Promise<Blob> {
  const response = await fetch(fileUrl);
  const fileBlob = await response.blob();
  
  // Create new PDF
  const pdf = new jsPDF();
  
  // Load original PDF
  const existingPdfBytes = await fileBlob.arrayBuffer();
  await pdf.loadFile(existingPdfBytes);
  
  // Get total pages
  const totalPages = pdf.getNumberOfPages();
  
  // Add signature to last page
  pdf.setPage(totalPages);
  
  // Add signature image
  const img = new Image();
  img.src = signature;
  await new Promise((resolve) => {
    img.onload = resolve;
  });
  
  pdf.addImage(img, 'PNG', position.x, position.y, 100, 50);
  
  // Add signature date
  const date = new Date().toLocaleDateString('pt-BR');
  pdf.setFontSize(10);
  pdf.setTextColor(100);
  pdf.text(`Data: ${date}`, position.x, position.y + 60);
  
  return pdf.output('blob');
}