import { supabase } from '../lib/supabase';

interface TestProgressUpdate {
  testId: string;
  score: number;
  answers: Array<{
    questionId: number;
    correct: boolean;
    answer: number;
  }>;
  timeSpent: number;
}

export async function updateTestProgress(data: TestProgressUpdate): Promise<void> {
  const { data: user } = await supabase.auth.getUser();
  if (!user.user) throw new Error('User not authenticated');

  try {
    const { error } = await supabase
      .from('user_test_results')
      .insert({
        user_id: user.user.id,
        test_id: data.testId,
        score: data.score,
        answers: data.answers,
        time_spent: data.timeSpent,
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error updating test progress:', error);
    throw new Error('Failed to save test progress');
  }
}

export async function loadUserProgress(): Promise<void> {
  const { data: user } = await supabase.auth.getUser();
  if (!user.user) return;

  try {
    // Load test results
    const { data: testResults, error: testError } = await supabase
      .from('user_test_results')
      .select('*')
      .eq('user_id', user.user.id)
      .order('completed_at', { ascending: false });

    if (testError) throw testError;

    // Update store with test results
    if (testResults) {
      testResults.forEach(result => {
        // Update your store here
      });
    }
  } catch (error) {
    console.error('Error loading user progress:', error);
  }
}