interface ShareData {
  title: string;
  text: string;
  url: string;
}

export async function shareProject(data: ShareData): Promise<void> {
  try {
    const shareText = `${data.title}\n\n${data.text}\n\n${data.url}`;
    
    // Try using clipboard API
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(shareText);
      alert('Link copiado para a área de transferência!');
      return;
    }

    // Fallback for browsers without clipboard API
    const textArea = document.createElement('textarea');
    textArea.value = shareText;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      alert('Link copiado para a área de transferência!');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      throw new Error('Não foi possível copiar para a área de transferência');
    } finally {
      document.body.removeChild(textArea);
    }
  } catch (error) {
    console.error('Error sharing:', error);
    alert('Não foi possível compartilhar o projeto. Por favor, tente novamente.');
  }
}