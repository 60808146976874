import React, { useState, useRef } from 'react';
import { FileText, Download, Share2 } from 'lucide-react';
import { ResumeForm } from './ResumeForm';
import { ResumeData } from './types';
import { exportToPDF, exportToWord, shareResume } from '../../services/resumeExport';
import { RESUME_TEMPLATES, initialData } from './config/templates';

export function ResumeBuilder() {
  const [selectedTemplate, setSelectedTemplate] = useState(RESUME_TEMPLATES[0]);
  const [formData, setFormData] = useState<ResumeData>(initialData);
  const resumeRef = useRef<HTMLDivElement>(null);

  const handleExport = async (format: 'pdf' | 'docx' | 'link') => {
    if (!resumeRef.current) return;

    switch (format) {
      case 'pdf':
        await exportToPDF(resumeRef.current);
        break;
      case 'docx':
        exportToWord(formData);
        break;
      case 'link':
        shareResume(formData);
        break;
    }
  };

  const SelectedTemplateComponent = selectedTemplate.component;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      {/* Template Selection */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Escolha um Template</h2>
        <div className="grid grid-cols-3 gap-4">
          {RESUME_TEMPLATES.map((template) => (
            <button
              key={template.id}
              onClick={() => setSelectedTemplate(template)}
              className={`p-4 border rounded-lg transition-all ${
                selectedTemplate.id === template.id
                  ? 'border-blue-500 bg-blue-50'
                  : 'border-gray-200 hover:border-blue-300'
              }`}
            >
              <h3 className="font-medium">{template.name}</h3>
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Form */}
        <div className="space-y-6">
          <ResumeForm data={formData} onChange={setFormData} />
          
          <div className="flex space-x-4">
            <button
              onClick={() => handleExport('pdf')}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Download className="h-5 w-5" />
              <span>PDF</span>
            </button>
            <button
              onClick={() => handleExport('docx')}
              className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
            >
              <Download className="h-5 w-5" />
              <span>Word</span>
            </button>
            <button
              onClick={() => handleExport('link')}
              className="flex items-center space-x-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              <Share2 className="h-5 w-5" />
              <span>Compartilhar</span>
            </button>
          </div>
        </div>

        {/* Preview */}
        <div className="bg-gray-100 rounded-lg p-4">
          <h3 className="text-lg font-medium mb-4">Pré-visualização</h3>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div ref={resumeRef} className="transform scale-[0.7] origin-top">
              <SelectedTemplateComponent data={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}