import React from 'react';

interface DashboardLayoutProps {
  children: React.ReactNode;
  title?: string;
}

export function DashboardLayout({ children, title }: DashboardLayoutProps) {
  return (
    <div className="p-4 md:p-6 space-y-6">
      {title && (
        <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
      )}
      {children}
    </div>
  );
}