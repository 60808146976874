import { supabase } from '../../lib/supabase';

export async function deleteDocument(documentId: string, fileUrls: string[]) {
  try {
    // Delete document record
    const { error: deleteError } = await supabase
      .from('documents')
      .delete()
      .eq('id', documentId);

    if (deleteError) throw deleteError;

    // Delete files from storage
    for (const url of fileUrls) {
      const filePath = url.split('/').slice(-2).join('/');
      const { error: storageError } = await supabase.storage
        .from('documents')
        .remove([filePath]);

      if (storageError) {
        console.error('Error deleting file:', storageError);
      }
    }
  } catch (error) {
    console.error('Error deleting document:', error);
    throw new Error('Erro ao excluir documento. Por favor, tente novamente.');
  }
}