import React, { useState, useEffect } from 'react';
import { 
  LayoutDashboard, FileText, MessageSquare, Users, 
  Settings, LogOut, GraduationCap, Briefcase, 
  Laptop, Brain, Menu, X, FileSignature, Compass 
} from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';

interface SidebarProps {
  activePage: string;
  onPageChange: (page: string) => void;
}

export function Sidebar({ activePage, onPageChange }: SidebarProps) {
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', id: 'dashboard' },
    { icon: FileText, label: 'Currículos', id: 'resumes' },
    { icon: MessageSquare, label: 'Chat', id: 'chat' },
    { icon: GraduationCap, label: 'Cursos', id: 'courses' },
    { icon: Briefcase, label: 'Vagas', id: 'jobs' },
    { icon: Laptop, label: 'Portfólio', id: 'portfolio' },
    { icon: Brain, label: 'Testes', id: 'tests' },
    { icon: Compass, label: 'Teste Vocacional', id: 'vocational' },
    { icon: FileSignature, label: 'Assinaturas', id: 'signatures' },
    { icon: Settings, label: 'Configurações', id: 'settings' },
  ];

  const handlePageChange = (pageId: string) => {
    onPageChange(pageId);
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-gray-800 text-white"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}

      <aside className={`
        fixed top-0 left-0 h-full w-64 bg-gray-900 text-white transform transition-transform duration-200 ease-in-out z-50
        flex flex-col
        ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
      `}>
        <div className="p-4">
          <img 
            src="https://i.ibb.co/8gRg9cgB/Marca-horizontal-colorida.png" 
            alt="Logo" 
            className="h-12 w-auto object-contain"
          />
        </div>

        <nav className="flex-1 overflow-y-auto py-6">
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => handlePageChange(item.id)}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium transition-colors ${
                    activePage === item.id
                      ? 'bg-blue-600 text-white'
                      : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                  }`}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <div className="p-4 border-t border-gray-800">
          <button
            onClick={logout}
            className="w-full flex items-center px-6 py-3 text-sm font-medium text-gray-300 hover:bg-gray-800 hover:text-white transition-colors"
          >
            <LogOut className="h-5 w-5 mr-3" />
            Sair
          </button>
        </div>
      </aside>
    </>
  );
}