import { supabase } from '../../lib/supabase';
import { generateSignature, addSignatureToDocument } from '../signatureGenerator';
import { uploadFile } from './documentStorage';
import { createDocumentRecord } from './documentQueries';
import { DocumentMetadata } from './types';

export { getDocuments } from './documentQueries';

export async function uploadAndSignDocument(file: File, metadata: DocumentMetadata) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('User not authenticated');

  try {
    // Upload original file
    const originalUrl = await uploadFile(user.id, file);

    // Generate signature
    const signature = await generateSignature({ 
      name: user.user_metadata.name || user.email 
    });

    // Add signature to document
    const signedPdfBlob = await addSignatureToDocument(originalUrl, signature);

    // Upload signed document
    const signedUrl = await uploadFile(user.id, signedPdfBlob, 'signed-');

    // Create document record
    return await createDocumentRecord({
      user_id: user.id,
      title: metadata.title,
      description: metadata.description,
      file_url: signedUrl,
      original_url: originalUrl,
      status: 'completed',
      signers: [{
        name: user.user_metadata.name || user.email,
        email: user.email,
        signed: true,
        signed_at: new Date().toISOString()
      }]
    });
  } catch (error) {
    console.error('Error processing document:', error);
    throw error;
  }
}