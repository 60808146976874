import { supabase } from '../lib/supabase';

export async function changePassword(currentPassword: string, newPassword: string): Promise<void> {
  try {
    // First verify the current password by attempting to sign in
    const { error: signInError } = await supabase.auth.signInWithPassword({
      email: supabase.auth.getUser().then(({ data }) => data.user?.email || ''),
      password: currentPassword,
    });

    if (signInError) {
      throw new Error('Senha atual incorreta');
    }

    // Update to new password
    const { error: updateError } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (updateError) throw updateError;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error instanceof Error ? error : new Error('Erro ao alterar senha');
  }
}