import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export function Header() {
  const navigate = useNavigate();

  return (
    <header className="fixed w-full bg-white shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center">
            <img 
              src="https://i.ibb.co/8gRg9cgB/Marca-horizontal-colorida.png" 
              alt="Logo" 
              className="h-16 object-contain" // Increased from h-10
            />
          </Link>
          
          <nav className="hidden md:flex space-x-8">
            <a href="#features" className="text-gray-600 hover:text-blue-600 transition-colors">
              Recursos
            </a>
            <a href="#pricing" className="text-gray-600 hover:text-blue-600 transition-colors">
              Planos
            </a>
            <a href="#testimonials" className="text-gray-600 hover:text-blue-600 transition-colors">
              Depoimentos
            </a>
          </nav>
          
          <div className="flex items-center space-x-4">
            <Link 
              to="/login" 
              className="text-gray-600 hover:text-blue-600 transition-colors"
            >
              Entrar
            </Link>
            <Link
              to="/signup"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Começar Grátis
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}