import React from 'react';
import { RefreshCw, BookOpen, Briefcase, Target } from 'lucide-react';
import { CareerProfile } from './types';

interface VocationalResultsProps {
  results: CareerProfile;
  onRestart: () => void;
}

export function VocationalResults({ results, onRestart }: VocationalResultsProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Seu Perfil Vocacional</h2>
        <p className="text-gray-600">
          Com base em suas respostas, identificamos as seguintes características e recomendações
        </p>
      </div>

      <div className="grid gap-6 mb-8">
        <div className="p-6 bg-blue-50 rounded-lg">
          <div className="flex items-center space-x-3 mb-4">
            <Target className="h-6 w-6 text-blue-600" />
            <h3 className="text-lg font-semibold text-blue-900">Áreas Recomendadas</h3>
          </div>
          <div className="space-y-2">
            {results.recommendedAreas.map((area, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-blue-600 rounded-full" />
                <span>{area}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 bg-green-50 rounded-lg">
          <div className="flex items-center space-x-3 mb-4">
            <Briefcase className="h-6 w-6 text-green-600" />
            <h3 className="text-lg font-semibold text-green-900">Carreiras Sugeridas</h3>
          </div>
          <div className="space-y-2">
            {results.careers.map((career, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-green-600 rounded-full" />
                <span>{career}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 bg-purple-50 rounded-lg">
          <div className="flex items-center space-x-3 mb-4">
            <BookOpen className="h-6 w-6 text-purple-600" />
            <h3 className="text-lg font-semibold text-purple-900">Cursos Recomendados</h3>
          </div>
          <div className="space-y-2">
            {results.recommendedCourses.map((course, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-purple-600 rounded-full" />
                <span>{course}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          onClick={onRestart}
          className="inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          <RefreshCw className="h-4 w-4" />
          <span>Refazer Teste</span>
        </button>
      </div>
    </div>
  );
}