import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { Pricing } from './components/Pricing';
import { Testimonials } from './components/Testimonials';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { Dashboard } from './pages/Dashboard';
import { UserSettings } from './components/dashboard/settings/UserSettings';
import { useAuth } from './hooks/useAuth';
import { useThemeStore } from './store/themeStore';

export default function App() {
  const { isAuthenticated } = useAuth();
  const { theme } = useThemeStore();

  // Apply theme on mount and theme changes
  useEffect(() => {
    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      document.documentElement.classList.toggle('dark', systemTheme === 'dark');
    } else {
      document.documentElement.classList.toggle('dark', theme === 'dark');
    }
  }, [theme]);

  if (isAuthenticated) {
    return (
      <Router>
        <Routes>
          <Route path="/settings" element={<UserSettings />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <main>
                <Hero onGetStarted={() => {}} />
                <Pricing />
                <Testimonials />
              </main>
              <footer className="bg-gray-900 text-white py-12">
                {/* Footer content */}
              </footer>
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </Router>
  );
}