import React, { useState } from 'react';
import { Brain, ChevronRight, RefreshCw } from 'lucide-react';
import { VocationalQuestion } from './VocationalQuestion';
import { VocationalResults } from './VocationalResults';
import { Question, CareerProfile } from './types';
import { questions } from './questions';
import { analyzeAnswers } from './analyzer';

export function VocationalTest() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<Record<number, number>>({});
  const [results, setResults] = useState<CareerProfile | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAnswer = (questionId: number, value: number) => {
    setAnswers(prev => ({ ...prev, [questionId]: value }));
    
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    } else {
      finishTest();
    }
  };

  const finishTest = async () => {
    setIsLoading(true);
    try {
      const results = await analyzeAnswers(answers);
      setResults(results);
    } catch (error) {
      console.error('Error analyzing test results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const restartTest = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setResults(null);
  };

  if (results) {
    return (
      <div className="max-w-4xl mx-auto">
        <VocationalResults results={results} onRestart={restartTest} />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Brain className="h-6 w-6 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold">Teste Vocacional</h2>
          </div>
          <div className="text-sm text-gray-500">
            Questão {currentQuestion + 1} de {questions.length}
          </div>
        </div>

        <div className="relative">
          <div className="h-2 bg-gray-200 rounded-full mb-6">
            <div
              className="h-2 bg-blue-600 rounded-full transition-all duration-300"
              style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
            />
          </div>

          {isLoading ? (
            <div className="text-center py-12">
              <RefreshCw className="h-8 w-8 animate-spin text-blue-600 mx-auto mb-4" />
              <p className="text-gray-600">Analisando suas respostas...</p>
            </div>
          ) : (
            <VocationalQuestion
              question={questions[currentQuestion]}
              onAnswer={handleAnswer}
              currentAnswer={answers[questions[currentQuestion].id]}
            />
          )}
        </div>
      </div>
    </div>
  );
}