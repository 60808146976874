export const PAYMENT_PLANS = [
  {
    id: 'plan_free',
    name: 'Gratuito',
    price: 0,
    interval: 'month',
    features: [
      'Acesso básico ao gerador de currículos',
      'Chatbot com funções básicas',
      '1 currículo por mês',
      'Teste vocacional básico'
    ]
  },
  {
    id: 'plan_pro',
    name: 'Pro',
    price: 19.90,
    interval: 'month',
    features: [
      'Modelos avançados de currículo',
      'Chatbot ilimitado',
      'Análise de palavras-chave',
      'Suporte prioritário',
      'Cursos básicos',
      'Teste vocacional completo'
    ]
  },
  {
    id: 'plan_master',
    name: 'Master',
    price: 49.90,
    interval: 'month',
    features: [
      'Todos os recursos Pro',
      'Acesso ilimitado',
      'Simulação de entrevistas',
      'Cursos e certificados',
      'Assinatura digital ilimitada',
      'Mentoria personalizada'
    ]
  }
] as const;