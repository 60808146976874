import { loadStripe } from '@stripe/stripe-js';
import { supabase } from '../../lib/supabase';
import { PaymentSession, SubscriptionStatus } from './types';
import { security } from '../../lib/security';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const paymentService = {
  createCheckoutSession: async (priceId: string): Promise<PaymentSession> => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase.functions.invoke('create-checkout-session', {
        body: {
          priceId,
          userId: user.id,
          customerEmail: user.email,
          successUrl: `${window.location.origin}/payment/success`,
          cancelUrl: `${window.location.origin}/payment/cancel`
        }
      });

      if (error || !data?.sessionUrl) {
        throw new Error('Failed to create checkout session');
      }

      return {
        id: data.sessionId,
        url: data.sessionUrl
      };
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw new Error('Failed to initialize payment. Please try again.');
    }
  },

  getSubscriptionStatus: async (): Promise<SubscriptionStatus> => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      return {
        active: data?.status === 'active',
        plan: data?.plan_id || 'free',
        currentPeriodEnd: data?.current_period_end || '',
        cancelAtPeriodEnd: data?.cancel_at_period_end || false
      };
    } catch (error) {
      console.error('Error getting subscription status:', error);
      return {
        active: false,
        plan: 'free',
        currentPeriodEnd: '',
        cancelAtPeriodEnd: false
      };
    }
  }
};