import React from 'react';
import { Check } from 'lucide-react';

interface PlanCardProps {
  name: string;
  price: number;
  interval: 'month' | 'year';
  features: string[];
  isPopular?: boolean;
  onSelect: () => void;
}

export function PlanCard({
  name,
  price,
  interval,
  features,
  isPopular,
  onSelect
}: PlanCardProps) {
  return (
    <div className={`bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 ${
      isPopular ? 'border-2 border-blue-500' : ''
    }`}>
      {isPopular && (
        <div className="bg-blue-500 text-white text-center py-1 text-sm">
          Mais Popular
        </div>
      )}
      
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-4">{name}</h3>
        <div className="mb-6">
          <span className="text-4xl font-bold">
            {price === 0 ? 'Grátis' : `R$ ${price.toFixed(2)}`}
          </span>
          {price > 0 && (
            <span className="text-gray-500">/{interval === 'month' ? 'mês' : 'ano'}</span>
          )}
        </div>

        <ul className="space-y-3 mb-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-gray-600">
              <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>

        <button
          onClick={onSelect}
          className={`w-full py-2 rounded-lg transition-colors ${
            isPopular
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
          }`}
        >
          {price === 0 ? 'Começar Grátis' : 'Assinar Agora'}
        </button>
      </div>
    </div>
  );
}