import React, { useState } from 'react';
import { Brain, Filter } from 'lucide-react';
import { TestCard } from './TestCard';
import { QuizPlayer } from './QuizPlayer';
import { TestPerformance } from './TestPerformance';
import { AVAILABLE_TESTS } from '../../../data/tests';
import { Test, TestCategory, TestResult } from './types';
import { useDashboardStore } from '../../../store/dashboardStore';
import { updateTestProgress } from '../../../services/progress';

export function TestList() {
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);
  const [filter, setFilter] = useState<{
    category: TestCategory | 'all';
    difficulty: string;
  }>({
    category: 'all',
    difficulty: 'all',
  });

  const { addActivity, updateTestScore, testResults } = useDashboardStore();

  const handleTestComplete = async (score: number, timeSpent: number, answers: any[]) => {
    if (!selectedTest) return;

    try {
      const result: TestResult = {
        testId: selectedTest.id,
        score,
        totalQuestions: selectedTest.questions.length,
        completedAt: new Date(),
        timeSpent,
        answers
      };

      // Update progress in database
      await updateTestProgress({
        testId: selectedTest.id,
        score,
        answers,
        timeSpent
      });

      // Update local state
      updateTestScore(result);

      // Add activity
      addActivity({
        type: 'test',
        title: `Completou ${selectedTest.title} com ${score}%`,
      });

      setSelectedTest(null);
    } catch (error) {
      console.error('Error saving test progress:', error);
      alert('Erro ao salvar progresso do teste. Por favor, tente novamente.');
    }
  };

  const filteredTests = AVAILABLE_TESTS.filter((test) => {
    const matchesCategory = filter.category === 'all' || test.category === filter.category;
    const matchesDifficulty = filter.difficulty === 'all' || test.difficulty === filter.difficulty;
    return matchesCategory && matchesDifficulty;
  });

  if (selectedTest) {
    return (
      <QuizPlayer
        test={selectedTest}
        onComplete={handleTestComplete}
        onBack={() => setSelectedTest(null)}
      />
    );
  }

  return (
    <div className="space-y-8">
      <TestPerformance results={testResults} />

      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
          <h2 className="text-2xl font-semibold">Testes Disponíveis</h2>
          <div className="flex gap-4">
            <select
              value={filter.category}
              onChange={(e) => setFilter(prev => ({ ...prev, category: e.target.value as TestCategory | 'all' }))}
              className="px-4 py-2 border rounded-lg"
            >
              <option value="all">Todas as Categorias</option>
              <option value="logic">Raciocínio Lógico</option>
              <option value="technical">Técnico</option>
              <option value="language">Idiomas</option>
              <option value="soft-skills">Soft Skills</option>
            </select>
            <select
              value={filter.difficulty}
              onChange={(e) => setFilter(prev => ({ ...prev, difficulty: e.target.value }))}
              className="px-4 py-2 border rounded-lg"
            >
              <option value="all">Todas as Dificuldades</option>
              <option value="Fácil">Fácil</option>
              <option value="Intermediário">Intermediário</option>
              <option value="Avançado">Avançado</option>
            </select>
          </div>
        </div>
      </div>

      {filteredTests.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredTests.map((test) => (
            <TestCard
              key={test.id}
              {...test}
              onClick={() => setSelectedTest(test)}
              result={testResults.find((r) => r.testId === test.id)}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <Brain className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Nenhum teste encontrado
          </h3>
          <p className="text-gray-600">
            Tente ajustar seus filtros para encontrar mais testes
          </p>
        </div>
      )}
    </div>
  );
}