import React, { useState } from 'react';
import { PlanCard } from './payment/PlanCard';
import { PaymentModal } from './payment/PaymentModal';
import { PAYMENT_PLANS } from '../services/payment/plans';

export function Pricing() {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  return (
    <section id="pricing" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Escolha o plano ideal para você</h2>
          <p className="text-gray-600">Comece gratuitamente e evolua conforme suas necessidades</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {PAYMENT_PLANS.map((plan) => (
            <PlanCard
              key={plan.id}
              name={plan.name}
              price={plan.price}
              interval={plan.interval}
              features={plan.features}
              isPopular={plan.id === 'plan_pro'}
              onSelect={() => setSelectedPlan(plan.id)}
            />
          ))}
        </div>

        {selectedPlan && (
          <PaymentModal
            planId={selectedPlan}
            onClose={() => setSelectedPlan(null)}
          />
        )}
      </div>
    </section>
  );
}