import React, { useState, useEffect, useRef } from 'react';
import { Send } from 'lucide-react';
import { getChatResponse } from '../../services/gemini';
import { saveChatHistory, getChatHistory, cleanupOldMessages } from '../../services/chatService';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';
import { LoadingIndicator } from './LoadingIndicator';
import { useChatMessages } from '../../hooks/useChatMessages';

export function ChatInterface() {
  const { messages, addMessage, loadMessages, clearMessages } = useChatMessages();
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialize = async () => {
      await cleanupOldMessages();
      await loadMessages();
    };
    initialize();

    // Clear messages after 24 hours
    const interval = setInterval(() => {
      clearMessages();
      loadMessages();
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    setInput('');
    setIsLoading(true);

    try {
      addMessage({ type: 'user', content: userMessage });
      const response = await getChatResponse(userMessage);
      await saveChatHistory(userMessage, response);
      addMessage({ type: 'bot', content: response });
    } catch (error) {
      console.error('Error in chat:', error);
      addMessage({ 
        type: 'bot', 
        content: 'Desculpe, ocorreu um erro. Por favor, tente novamente.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-[600px] bg-white rounded-lg shadow-lg">
      <div className="p-4 border-b">
        <h2 className="text-xl font-semibold">Assistente Virtual</h2>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <ChatMessage key={message.id} message={message} />
        ))}
        {isLoading && <LoadingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 border-t">
        <ChatInput
          value={input}
          onChange={setInput}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}