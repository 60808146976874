import React, { useState, useEffect } from 'react';
import { FileText, Plus } from 'lucide-react';
import { Document } from '../../../services/documents/types';
import { getDocuments } from '../../../services/documents/documentQueries';
import { DocumentCard } from './DocumentCard';
import { SignatureUploader } from './SignatureUploader';

export function SignatureList() {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      const data = await getDocuments();
      setDocuments(data);
    } catch (error) {
      console.error('Error loading documents:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Assinaturas Digitais</h2>
        <button
          onClick={() => setIsUploading(true)}
          className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          <Plus className="h-5 w-5" />
          <span>Novo Documento</span>
        </button>
      </div>

      {isUploading ? (
        <SignatureUploader
          onComplete={() => {
            setIsUploading(false);
            loadDocuments();
          }}
          onCancel={() => setIsUploading(false)}
        />
      ) : documents.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {documents.map((document) => (
            <DocumentCard
              key={document.id}
              document={document}
              onUpdate={loadDocuments}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <FileText className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Nenhum documento encontrado
          </h3>
          <p className="text-gray-600">
            Comece adicionando um documento para assinatura
          </p>
        </div>
      )}
    </div>
  );
}