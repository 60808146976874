import axios from 'axios';
import { JobType } from '../types/job';

const ADZUNA_API = 'https://api.adzuna.com/v1/api/jobs/br/search/1';

export async function fetchJobs(query: string): Promise<JobType[]> {
  try {
    const response = await axios.get(ADZUNA_API, {
      params: {
        app_id: import.meta.env.VITE_ADZUNA_APP_ID,
        app_key: import.meta.env.VITE_ADZUNA_API_KEY,
        what: query || '',
        results_per_page: 10,
      },
      headers: {
        'Accept': 'application/json'
      }
    });

    return response.data?.results?.map((job: any) => ({
      id: job.id?.toString() || Math.random().toString(),
      title: job.title || 'Título não disponível',
      company: job.company?.display_name || 'Empresa não informada',
      location: job.location?.display_name || 'Local não informado',
      salary: job.salary_min ? 
        `R$ ${job.salary_min.toLocaleString('pt-BR')} - R$ ${job.salary_max.toLocaleString('pt-BR')}` 
        : 'Salário não informado',
      type: job.contract_time || 'Tempo Integral',
      description: job.description || 'Descrição não disponível',
      url: job.redirect_url || '#',
      match: Math.floor(Math.random() * 30) + 70,
    })) || [];
  } catch (error) {
    console.error('Error fetching jobs:', error);
    return [];
  }
}