import { jsPDF } from 'jspdf';

interface CertificateData {
  studentName: string;
  courseName: string;
  completionDate: Date;
  duration: string;
}

export async function generateCertificate(data: CertificateData): Promise<void> {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'px',
    format: [800, 600]
  });

  // Set background color
  doc.setFillColor(249, 250, 251);
  doc.rect(0, 0, 800, 600, 'F');

  // Add border
  doc.setDrawColor(59, 130, 246);
  doc.setLineWidth(5);
  doc.rect(20, 20, 760, 560);

  // Add header
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(40);
  doc.setTextColor(30, 58, 138);
  doc.text('Certificado de Conclusão', 400, 100, { align: 'center' });

  // Add content
  doc.setFontSize(20);
  doc.setTextColor(55, 65, 81);
  doc.text('Este certificado é concedido a', 400, 200, { align: 'center' });

  // Add student name
  doc.setFontSize(30);
  doc.setTextColor(17, 24, 39);
  doc.text(data.studentName, 400, 250, { align: 'center' });

  // Add course details
  doc.setFontSize(20);
  doc.setTextColor(55, 65, 81);
  doc.text('pela conclusão do curso', 400, 300, { align: 'center' });

  doc.setFontSize(25);
  doc.setTextColor(17, 24, 39);
  doc.text(data.courseName, 400, 350, { align: 'center' });

  // Add completion date and duration
  doc.setFontSize(16);
  doc.setTextColor(75, 85, 99);
  const dateStr = data.completionDate.toLocaleDateString('pt-BR');
  doc.text(`Concluído em ${dateStr}`, 400, 400, { align: 'center' });
  doc.text(`Duração: ${data.duration}`, 400, 430, { align: 'center' });

  // Add signature line
  doc.setDrawColor(209, 213, 219);
  doc.setLineWidth(1);
  doc.line(300, 500, 500, 500);
  
  doc.setFontSize(14);
  doc.text('Assinatura do Instrutor', 400, 520, { align: 'center' });

  // Save the PDF
  doc.save(`certificado-${data.courseName.toLowerCase().replace(/\s+/g, '-')}.pdf`);
}