import React from 'react';
import { FileText, Briefcase, Brain, Code } from 'lucide-react';
import { useDashboardStore } from '../../store/dashboardStore';

const activityIcons = {
  course: FileText,
  test: Brain,
  job: Briefcase,
  portfolio: Code,
};

export function ActivityFeed() {
  const activities = useDashboardStore((state) => state.recentActivities);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">
        Atividades Recentes
      </h2>
      <div className="space-y-4">
        {activities.map((activity) => {
          const Icon = activityIcons[activity.type];
          return (
            <div key={activity.id} className="flex items-center space-x-4">
              <div className="h-10 w-10 rounded-full bg-blue-50 flex items-center justify-center text-blue-600">
                <Icon className="h-5 w-5" />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {activity.title}
                </p>
                <p className="text-sm text-gray-500">
                  {new Date(activity.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          );
        })}
        {activities.length === 0 && (
          <p className="text-gray-500 text-center py-4">
            Nenhuma atividade recente
          </p>
        )}
      </div>
    </div>
  );
}