import React from 'react';
import { Play, Clock, Award } from 'lucide-react';

interface CourseCardProps {
  id: string;
  title: string;
  duration: string;
  level: string;
  progress?: number;
  image: string;
  onClick: () => void;
}

export function CourseCard({ 
  title, 
  duration, 
  level, 
  progress = 0, 
  image,
  onClick 
}: CourseCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-40 bg-gray-200 relative">
        <img src={image} alt={title} className="w-full h-full object-cover" />
        {progress > 0 && (
          <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200">
            <div 
              className="h-full bg-blue-600 transition-all duration-300" 
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
      <div className="p-4">
        <h3 className="font-semibold text-lg mb-2">{title}</h3>
        <div className="flex items-center justify-between text-sm text-gray-600">
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            <span>{duration}</span>
          </div>
          <div className="flex items-center">
            <Award className="h-4 w-4 mr-1" />
            <span>{level}</span>
          </div>
        </div>
        <button 
          onClick={onClick}
          className="mt-4 w-full flex items-center justify-center space-x-2 p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Play className="h-4 w-4" />
          <span>{progress > 0 ? 'Continuar' : 'Começar'}</span>
        </button>
      </div>
    </div>
  );
}