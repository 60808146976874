import { ResumeTemplate, ResumeData } from '../types';
import { ModernTemplate } from '../templates/ModernTemplate';
import { MinimalTemplate } from '../templates/MinimalTemplate';
import { ClassicTemplate } from '../templates/ClassicTemplate';

export const RESUME_TEMPLATES: ResumeTemplate[] = [
  {
    id: 'modern',
    name: 'Moderno',
    preview: 'modern-preview.png',
    component: ModernTemplate,
  },
  {
    id: 'minimal',
    name: 'Minimalista',
    preview: 'minimal-preview.png',
    component: MinimalTemplate,
  },
  {
    id: 'classic',
    name: 'Clássico',
    preview: 'classic-preview.png',
    component: ClassicTemplate,
  },
];

export const initialData: ResumeData = {
  name: '',
  email: '',
  phone: '',
  summary: '',
  experience: [
    {
      title: '',
      company: '',
      period: '',
      description: '',
    },
  ],
  education: [
    {
      degree: '',
      institution: '',
      period: '',
    },
  ],
  skills: [''],
};