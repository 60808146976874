import React from 'react';
import { Eye, Github, Link as LinkIcon, Share2 } from 'lucide-react';
import { Project } from './types';

interface ProjectCardProps {
  project: Project;
  onShare: (project: Project) => void;
}

export function ProjectCard({ project, onShare }: ProjectCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-48 bg-gray-200 relative">
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-full object-cover"
        />
        {project.category && (
          <span className="absolute top-2 right-2 px-2 py-1 bg-blue-600 text-white text-xs rounded-full">
            {project.category}
          </span>
        )}
      </div>
      
      <div className="p-4">
        <h3 className="font-semibold text-lg mb-2">{project.title}</h3>
        <p className="text-gray-600 text-sm mb-4">{project.description}</p>
        
        <div className="flex flex-wrap gap-2 mb-4">
          {project.technologies.map((tech, index) => (
            <span
              key={index}
              className="px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded-full"
            >
              {tech}
            </span>
          ))}
        </div>

        <div className="flex items-center justify-between text-sm text-gray-500 mb-4">
          <div className="flex items-center">
            <Eye className="h-4 w-4 mr-1" />
            <span>{project.views}</span>
          </div>
          <div className="flex items-center">
            <Share2 className="h-4 w-4 mr-1" />
            <span>{project.shares}</span>
          </div>
        </div>

        <div className="flex space-x-2">
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex-1 flex items-center justify-center space-x-2 p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <LinkIcon className="h-4 w-4" />
            <span>Ver Projeto</span>
          </a>
          {project.github_link && (
            <a
              href={project.github_link}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <Github className="h-4 w-4" />
            </a>
          )}
          <button
            onClick={() => onShare(project)}
            className="p-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <Share2 className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
}