import React from 'react';
import { FileText, MessageSquare, GraduationCap, Briefcase, Laptop, Brain, FileSignature, Target, Bot } from 'lucide-react';
import { Link } from 'react-router-dom';

interface HeroProps {
  onGetStarted: () => void;
}

export function Hero({ onGetStarted }: HeroProps) {
  return (
    <div className="pt-20 bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6 animate-fade-in">
            Sua Jornada Profissional Começa Aqui
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto animate-slide-up">
            Transforme seu futuro profissional com nossa plataforma completa. Desenvolvida especialmente para jovens de 14 a 29 anos, oferecemos todas as ferramentas necessárias para impulsionar sua carreira.
          </p>
          <Link 
            to="/signup"
            className="inline-block bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transform hover:scale-105 transition-all animate-bounce-subtle"
          >
            Comece Grátis Agora
          </Link>
        </div>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <FileText className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Gerador de Currículos</h3>
            <p className="text-gray-600">Crie currículos profissionais e personalizados em minutos com nossos templates modernos.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <Bot className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Chatbot Inteligente</h3>
            <p className="text-gray-600">Receba orientação personalizada e suporte emocional para sua jornada profissional.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <GraduationCap className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Cursos Online</h3>
            <p className="text-gray-600">Acesse cursos de qualidade para desenvolver habilidades essenciais para sua carreira.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <Briefcase className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Vagas de Emprego</h3>
            <p className="text-gray-600">Encontre oportunidades alinhadas com seu perfil e objetivos profissionais.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <Laptop className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Portfólio Digital</h3>
            <p className="text-gray-600">Crie um portfólio profissional para destacar seus projetos e conquistas.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <Brain className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Teste Vocacional</h3>
            <p className="text-gray-600">Descubra sua vocação e áreas profissionais mais compatíveis com seu perfil.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <FileSignature className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Assinatura Digital</h3>
            <p className="text-gray-600">Assine documentos profissionais de forma segura e prática.</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-all">
            <Target className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Planejamento de Carreira</h3>
            <p className="text-gray-600">Desenvolva um plano estratégico para sua carreira com mentoria especializada.</p>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="relative bg-blue-600 p-12 rounded-3xl overflow-hidden mb-20">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
          <div className="relative z-10">
            <h2 className="text-3xl font-bold text-white mb-12 text-center">
              Por que escolher nossa plataforma?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl text-white hover:transform hover:scale-105 transition-all">
                <h3 className="text-xl font-semibold mb-4">Tudo em Um Só Lugar</h3>
                <p className="text-white/90">Todas as ferramentas que você precisa para desenvolver sua carreira em uma única plataforma integrada.</p>
              </div>
              <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl text-white hover:transform hover:scale-105 transition-all">
                <h3 className="text-xl font-semibold mb-4">Suporte Personalizado</h3>
                <p className="text-white/90">Mentoria e orientação adaptadas ao seu perfil e objetivos profissionais.</p>
              </div>
              <div className="bg-white/10 backdrop-blur-lg p-8 rounded-xl text-white hover:transform hover:scale-105 transition-all">
                <h3 className="text-xl font-semibold mb-4">Resultados Comprovados</h3>
                <p className="text-white/90">Milhares de jovens já transformaram suas carreiras com nossa plataforma.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/signup"
            className="inline-block bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transform hover:scale-105 transition-all"
          >
            Comece Sua Jornada Agora
          </Link>
          <p className="mt-4 text-gray-600">Experimente gratuitamente por 7 dias</p>
        </div>
      </div>
    </div>
  );
}