import React, { useState } from 'react';
import { ProfileSection } from './sections/ProfileSection';
import { SecuritySection } from './sections/SecuritySection';
import { PreferencesSection } from './sections/PreferencesSection';
import { useAuth } from '../../../hooks/useAuth';
import { UserProfile } from '../../../types/user';

export function UserSettings() {
  const { user, updateProfile } = useAuth();
  const [activeTab, setActiveTab] = useState('profile');

  const handleProfileUpdate = async (updates: Partial<UserProfile>) => {
    try {
      await updateProfile(updates);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const tabs = [
    { id: 'profile', label: 'Perfil' },
    { id: 'security', label: 'Segurança' },
    { id: 'preferences', label: 'Preferências' },
  ];

  return (
    <div className="bg-gray-50">
      <div className="max-w-4xl mx-auto py-6 px-4">
        <h2 className="text-2xl font-bold mb-6">Configurações da Conta</h2>

        <div className="mb-6 border-b border-gray-200">
          <nav className="flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === tab.id
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          {activeTab === 'profile' && (
            <ProfileSection
              profile={user as UserProfile}
              onUpdate={handleProfileUpdate}
            />
          )}
          {activeTab === 'security' && (
            <SecuritySection
              twoFactorEnabled={user?.security?.twoFactorEnabled || false}
              onToggleTwoFactor={(enabled) =>
                handleProfileUpdate({
                  security: { ...user?.security, twoFactorEnabled: enabled },
                })
              }
              onChangePassword={async (current, newPass) => {
                // Implement password change logic
              }}
            />
          )}
          {activeTab === 'preferences' && (
            <PreferencesSection
              preferences={user?.preferences || {
                emailNotifications: true,
                theme: 'light',
                language: 'pt-BR',
              }}
              onUpdate={(preferences) => handleProfileUpdate({ preferences })}
            />
          )}
        </div>
      </div>
    </div>
  );
}