import React from 'react';
import { useDashboardStore } from '../../store/dashboardStore';

export function ProgressOverview() {
  const {
    totalCourses,
    completedCourses,
    totalTests,
    completedTests,
    averageScore,
  } = useDashboardStore();

  const stats = [
    {
      label: 'Progresso dos Cursos',
      value: `${completedCourses}/${totalCourses}`,
      percentage: totalCourses ? (completedCourses / totalCourses) * 100 : 0,
    },
    {
      label: 'Testes Realizados',
      value: `${completedTests}/${totalTests}`,
      percentage: totalTests ? (completedTests / totalTests) * 100 : 0,
    },
    {
      label: 'Média nos Testes',
      value: `${averageScore}%`,
      percentage: averageScore,
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">
        Visão Geral do Progresso
      </h2>
      <div className="space-y-6">
        {stats.map((stat, index) => (
          <div key={index}>
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-600">
                {stat.label}
              </span>
              <span className="text-sm font-medium text-gray-900">
                {stat.value}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-500"
                style={{ width: `${stat.percentage}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}