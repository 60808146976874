import { useState } from 'react';
import { getChatHistory } from '../services/chatService';

interface Message {
  id: string;
  type: 'user' | 'bot';
  content: string;
  timestamp: Date;
}

export function useChatMessages() {
  const [messages, setMessages] = useState<Message[]>([]);

  const loadMessages = async () => {
    const history = await getChatHistory();
    const formattedMessages = history.flatMap((item) => [
      {
        id: `${item.id}-user-${Date.now()}`,
        type: 'user' as const,
        content: item.message,
        timestamp: new Date(item.created_at)
      },
      {
        id: `${item.id}-bot-${Date.now()}`,
        type: 'bot' as const,
        content: item.response,
        timestamp: new Date(item.created_at)
      }
    ]);
    setMessages(formattedMessages);
  };

  const addMessage = (message: Omit<Message, 'id' | 'timestamp'>) => {
    const newMessage = {
      ...message,
      id: `${message.type}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      timestamp: new Date()
    };
    setMessages((prev) => [...prev, newMessage]);
  };

  const clearMessages = () => {
    setMessages([]);
  };

  return {
    messages,
    addMessage,
    loadMessages,
    clearMessages
  };
}