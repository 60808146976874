import React from 'react';
import { Bell, Moon, Globe } from 'lucide-react';
import { useThemeStore } from '../../../../store/themeStore';

interface PreferencesSectionProps {
  preferences: {
    emailNotifications: boolean;
  };
  onUpdate: (preferences: { emailNotifications: boolean }) => void;
}

export function PreferencesSection({ preferences, onUpdate }: PreferencesSectionProps) {
  const { theme, language, setTheme, setLanguage } = useThemeStore();

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium">Preferências</h3>

      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-white rounded-lg border">
          <div className="flex items-center space-x-3">
            <Bell className="h-5 w-5 text-gray-400" />
            <div>
              <p className="font-medium">Notificações por Email</p>
              <p className="text-sm text-gray-500">
                Receba atualizações sobre suas atividades
              </p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={preferences.emailNotifications}
              onChange={(e) => onUpdate({ emailNotifications: e.target.checked })}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className="p-4 bg-white rounded-lg border">
          <div className="flex items-center space-x-3 mb-4">
            <Moon className="h-5 w-5 text-gray-400" />
            <p className="font-medium">Tema</p>
          </div>
          <select
            value={theme}
            onChange={(e) => setTheme(e.target.value as 'light' | 'dark' | 'system')}
            className="block w-full rounded-lg border-gray-300 shadow-sm"
          >
            <option value="light">Claro</option>
            <option value="dark">Escuro</option>
            <option value="system">Sistema</option>
          </select>
        </div>

        <div className="p-4 bg-white rounded-lg border">
          <div className="flex items-center space-x-3 mb-4">
            <Globe className="h-5 w-5 text-gray-400" />
            <p className="font-medium">Idioma</p>
          </div>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value as 'pt-BR' | 'en-US')}
            className="block w-full rounded-lg border-gray-300 shadow-sm"
          >
            <option value="pt-BR">Português (Brasil)</option>
            <option value="en-US">English (US)</option>
          </select>
        </div>
      </div>
    </div>
  );
}