import { Course } from '../components/dashboard/courses/types';

export const COURSES: Course[] = [
  {
    id: '1',
    title: 'Desenvolvimento Web Fullstack',
    description: 'Aprenda a criar aplicações web completas com React, Node.js e MongoDB',
    duration: '40 horas',
    level: 'Intermediário',
    category: 'Desenvolvimento',
    progress: 0,
    image: 'https://images.unsplash.com/photo-1517180102446-f3ece451e9d8?w=500&q=80',
    instructor: {
      name: 'Daniel Silva',
      title: 'Senior Developer',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&q=80'
    },
    modules: [
      {
        id: '1-1',
        title: 'Introdução ao Desenvolvimento Web',
        duration: '2 horas',
        videoUrl: 'https://www.youtube.com/embed/Tn6-PIqc4UM',
        description: 'Fundamentos de HTML, CSS e JavaScript',
        completed: false
      },
      {
        id: '1-2',
        title: 'React Fundamentals',
        duration: '4 horas',
        videoUrl: 'https://www.youtube.com/embed/Rh3tobg7hEo',
        description: 'Componentes, Props, State e Hooks',
        completed: false
      },
      {
        id: '1-3',
        title: 'Backend com Node.js',
        duration: '4 horas',
        videoUrl: 'https://www.youtube.com/embed/BN_8bCfVp88',
        description: 'APIs REST, Express e MongoDB',
        completed: false
      }
    ]
  },
  {
    id: '2',
    title: 'UX/UI Design Masterclass',
    description: 'Design de interfaces modernas e experiências de usuário incríveis',
    duration: '30 horas',
    level: 'Intermediário',
    category: 'Design',
    progress: 0,
    image: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?w=500&q=80',
    instructor: {
      name: 'Marina Costa',
      title: 'UX Designer Senior',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&q=80'
    },
    modules: [
      {
        id: '2-1',
        title: 'Fundamentos de UX',
        duration: '3 horas',
        videoUrl: 'https://www.youtube.com/embed/t0aCoqXKFOU',
        description: 'Princípios de design e psicologia do usuário',
        completed: false
      },
      {
        id: '2-2',
        title: 'Design Systems',
        duration: '4 horas',
        videoUrl: 'https://www.youtube.com/embed/0S_2t1ED5Yg',
        description: 'Criação e manutenção de sistemas de design',
        completed: false
      }
    ]
  },
  {
    id: '3',
    title: 'Marketing Digital Avançado',
    description: 'Estratégias avançadas de marketing digital e growth hacking',
    duration: '25 horas',
    level: 'Avançado',
    category: 'Marketing',
    progress: 0,
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=500&q=80',
    instructor: {
      name: 'Rafael Santos',
      title: 'Marketing Manager',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&q=80'
    },
    modules: [
      {
        id: '3-1',
        title: 'SEO Avançado',
        duration: '4 horas',
        videoUrl: 'https://www.youtube.com/embed/j5Sl6vx_l6k',
        description: 'Otimização para mecanismos de busca',
        completed: false
      },
      {
        id: '3-2',
        title: 'Marketing de Conteúdo',
        duration: '3 horas',
        videoUrl: 'https://www.youtube.com/embed/Xe5qMrAF5Vc',
        description: 'Estratégias de conteúdo e storytelling',
        completed: false
      }
    ]
  }
];