import React, { useState } from 'react';
import { GraduationCap } from 'lucide-react';
import { CourseCard } from './CourseCard';
import { CourseView } from './CourseView';
import { Course } from './types';
import { COURSES } from '../../../data/courses';

export function CourseList() {
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [filter, setFilter] = useState({ level: 'all', category: 'all' });

  const filteredCourses = COURSES.filter(course => {
    const matchesLevel = filter.level === 'all' || course.level === filter.level;
    const matchesCategory = filter.category === 'all' || course.category === filter.category;
    return matchesLevel && matchesCategory;
  });

  if (selectedCourse) {
    return (
      <CourseView
        course={selectedCourse}
        onBack={() => setSelectedCourse(null)}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row gap-4">
          <select
            value={filter.level}
            onChange={(e) => setFilter(prev => ({ ...prev, level: e.target.value }))}
            className="px-4 py-2 border rounded-lg"
          >
            <option value="all">Todos os Níveis</option>
            <option value="Básico">Básico</option>
            <option value="Intermediário">Intermediário</option>
            <option value="Avançado">Avançado</option>
          </select>
          <select
            value={filter.category}
            onChange={(e) => setFilter(prev => ({ ...prev, category: e.target.value }))}
            className="px-4 py-2 border rounded-lg"
          >
            <option value="all">Todas as Categorias</option>
            <option value="Desenvolvimento">Desenvolvimento</option>
            <option value="Design">Design</option>
            <option value="Marketing">Marketing</option>
          </select>
        </div>
      </div>

      {filteredCourses.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCourses.map((course) => (
            <CourseCard
              key={course.id}
              {...course}
              onClick={() => setSelectedCourse(course)}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <GraduationCap className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Nenhum curso encontrado
          </h3>
          <p className="text-gray-600">
            Tente ajustar seus filtros para encontrar mais cursos
          </p>
        </div>
      )}
    </div>
  );
}