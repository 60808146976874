import React, { useState, useEffect } from 'react';
import { Star, ArrowLeft, ArrowRight, Quote } from 'lucide-react';

interface Testimonial {
  id: number;
  content: string;
  author: string;
  role: string;
  image: string;
  rating: number;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    content: "O ProFuturo transformou minha carreira! O teste vocacional me ajudou a descobrir minha verdadeira vocação, e os cursos me prepararam perfeitamente para o mercado.",
    author: "Ana Silva",
    role: "Desenvolvedora Web",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150",
    rating: 5
  },
  {
    id: 2,
    content: "A mentoria personalizada e o suporte emocional foram fundamentais para meu desenvolvimento profissional. Recomendo para todos os jovens em início de carreira!",
    author: "Carlos Santos",
    role: "Gerente de Projetos",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150",
    rating: 5
  },
  {
    id: 3,
    content: "Os cursos e certificados são excelentes! A plataforma me ajudou a conseguir minha primeira oportunidade de emprego na área que sempre sonhei.",
    author: "Marina Costa",
    role: "Analista de Marketing",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150",
    rating: 5
  }
];

export function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextTestimonial = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    }
  };

  const prevTestimonial = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsAnimating(false), 500);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  useEffect(() => {
    const autoPlayTimer = setInterval(nextTestimonial, 5000);
    return () => clearInterval(autoPlayTimer);
  }, []);

  return (
    <section id="testimonials" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">O que nossos usuários dizem</h2>
          <p className="text-gray-600">Histórias reais de sucesso com o ProFuturo</p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          <div className="absolute top-0 left-0 -translate-x-1/2 translate-y-1/2 text-blue-600/10">
            <Quote className="w-24 h-24" />
          </div>
          
          <div className="relative bg-white rounded-2xl shadow-xl p-8 md:p-12">
            <div className={`transition-opacity duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
              <div className="flex justify-center mb-8">
                <img
                  src={testimonials[currentIndex].image}
                  alt={testimonials[currentIndex].author}
                  className="w-24 h-24 rounded-full object-cover border-4 border-blue-100"
                />
              </div>
              
              <div className="flex justify-center mb-6">
                {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                ))}
              </div>

              <blockquote className="text-xl text-gray-700 text-center mb-8">
                "{testimonials[currentIndex].content}"
              </blockquote>

              <div className="text-center">
                <p className="font-semibold text-gray-900">{testimonials[currentIndex].author}</p>
                <p className="text-blue-600">{testimonials[currentIndex].role}</p>
              </div>
            </div>

            <div className="absolute left-0 right-0 bottom-0 flex justify-center gap-4 -mb-6">
              <button
                onClick={prevTestimonial}
                className="p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <ArrowLeft className="h-6 w-6 text-gray-600" />
              </button>
              <button
                onClick={nextTestimonial}
                className="p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <ArrowRight className="h-6 w-6 text-gray-600" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}