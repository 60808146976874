import React from 'react';
import { ResumeData } from './types';

interface ResumeFormProps {
  data: ResumeData;
  onChange: (data: ResumeData) => void;
}

export function ResumeForm({ data, onChange }: ResumeFormProps) {
  const handleChange = (field: keyof ResumeData, value: any) => {
    onChange({ ...data, [field]: value });
  };

  const addExperience = () => {
    handleChange('experience', [
      ...data.experience,
      { title: '', company: '', period: '', description: '' },
    ]);
  };

  const updateExperience = (index: number, field: string, value: string) => {
    const newExperience = [...data.experience];
    newExperience[index] = { ...newExperience[index], [field]: value };
    handleChange('experience', newExperience);
  };

  const addEducation = () => {
    handleChange('education', [
      ...data.education,
      { degree: '', institution: '', period: '' },
    ]);
  };

  const updateEducation = (index: number, field: string, value: string) => {
    const newEducation = [...data.education];
    newEducation[index] = { ...newEducation[index], [field]: value };
    handleChange('education', newEducation);
  };

  const addSkill = () => {
    handleChange('skills', [...data.skills, '']);
  };

  const updateSkill = (index: number, value: string) => {
    const newSkills = [...data.skills];
    newSkills[index] = value;
    handleChange('skills', newSkills);
  };

  return (
    <div className="space-y-6">
      {/* Personal Information */}
      <div>
        <h3 className="text-lg font-medium mb-4">Informações Pessoais</h3>
        <div className="grid gap-4">
          <input
            type="text"
            placeholder="Nome Completo"
            value={data.name}
            onChange={(e) => handleChange('name', e.target.value)}
            className="w-full p-2 border rounded-lg"
          />
          <input
            type="email"
            placeholder="Email"
            value={data.email}
            onChange={(e) => handleChange('email', e.target.value)}
            className="w-full p-2 border rounded-lg"
          />
          <input
            type="tel"
            placeholder="Telefone"
            value={data.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
            className="w-full p-2 border rounded-lg"
          />
          <textarea
            placeholder="Resumo Profissional"
            value={data.summary}
            onChange={(e) => handleChange('summary', e.target.value)}
            rows={4}
            className="w-full p-2 border rounded-lg"
          />
        </div>
      </div>

      {/* Experience */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Experiência Profissional</h3>
          <button
            type="button"
            onClick={addExperience}
            className="text-blue-600 hover:text-blue-700"
          >
            + Adicionar
          </button>
        </div>
        {data.experience.map((exp, index) => (
          <div key={index} className="grid gap-4 mb-4 p-4 border rounded-lg">
            <input
              type="text"
              placeholder="Cargo"
              value={exp.title}
              onChange={(e) => updateExperience(index, 'title', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Empresa"
              value={exp.company}
              onChange={(e) => updateExperience(index, 'company', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Período"
              value={exp.period}
              onChange={(e) => updateExperience(index, 'period', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
            <textarea
              placeholder="Descrição"
              value={exp.description}
              onChange={(e) => updateExperience(index, 'description', e.target.value)}
              rows={3}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        ))}
      </div>

      {/* Education */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Formação Acadêmica</h3>
          <button
            type="button"
            onClick={addEducation}
            className="text-blue-600 hover:text-blue-700"
          >
            + Adicionar
          </button>
        </div>
        {data.education.map((edu, index) => (
          <div key={index} className="grid gap-4 mb-4 p-4 border rounded-lg">
            <input
              type="text"
              placeholder="Curso"
              value={edu.degree}
              onChange={(e) => updateEducation(index, 'degree', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Instituição"
              value={edu.institution}
              onChange={(e) => updateEducation(index, 'institution', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Período"
              value={edu.period}
              onChange={(e) => updateEducation(index, 'period', e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        ))}
      </div>

      {/* Skills */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Habilidades</h3>
          <button
            type="button"
            onClick={addSkill}
            className="text-blue-600 hover:text-blue-700"
          >
            + Adicionar
          </button>
        </div>
        <div className="grid gap-2">
          {data.skills.map((skill, index) => (
            <input
              key={index}
              type="text"
              placeholder="Habilidade"
              value={skill}
              onChange={(e) => updateSkill(index, e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
          ))}
        </div>
      </div>
    </div>
  );
}