import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ResumeData } from '../components/resume/types';

export async function exportToPDF(resumeElement: HTMLElement): Promise<void> {
  try {
    const canvas = await html2canvas(resumeElement, {
      scale: 2,
      useCORS: true,
      logging: false,
    });

    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width / 2, canvas.height / 2],
    });

    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / 2, canvas.height / 2);
    pdf.save('curriculo.pdf');
  } catch (error) {
    console.error('Error exporting PDF:', error);
    alert('Erro ao exportar o PDF. Por favor, tente novamente.');
  }
}

export function exportToWord(data: ResumeData): void {
  try {
    const template = generateWordTemplate(data);
    const blob = new Blob([template], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'curriculo.doc';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting Word:', error);
    alert('Erro ao exportar o documento Word. Por favor, tente novamente.');
  }
}

export async function shareResume(data: ResumeData): Promise<void> {
  try {
    const resumeText = generateShareableText(data);

    if (navigator.share) {
      try {
        await navigator.share({
          title: `Currículo de ${data.name}`,
          text: resumeText,
        });
        return;
      } catch (error) {
        if (error.name !== 'AbortError') {
          await copyToClipboard(resumeText);
        }
      }
    } else {
      await copyToClipboard(resumeText);
    }
  } catch (error) {
    console.error('Error sharing resume:', error);
    alert('Erro ao compartilhar o currículo. Por favor, tente novamente.');
  }
}

function generateWordTemplate(data: ResumeData): string {
  return `
    <html>
      <head>
        <meta charset="UTF-8">
        <style>
          body { font-family: Arial, sans-serif; }
          .header { text-align: center; margin-bottom: 20px; }
          .section { margin-bottom: 20px; }
          .section-title { color: #2563eb; font-size: 18px; margin-bottom: 10px; }
          .item { margin-bottom: 15px; }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>${data.name}</h1>
          <p>${data.email} | ${data.phone}</p>
        </div>
        
        ${data.summary ? `
          <div class="section">
            <h2 class="section-title">Resumo Profissional</h2>
            <p>${data.summary}</p>
          </div>
        ` : ''}

        ${data.experience.length > 0 ? `
          <div class="section">
            <h2 class="section-title">Experiência Profissional</h2>
            ${data.experience.map(exp => `
              <div class="item">
                <h3>${exp.title}</h3>
                <p>${exp.company}</p>
                <p><em>${exp.period}</em></p>
                <p>${exp.description}</p>
              </div>
            `).join('')}
          </div>
        ` : ''}

        ${data.education.length > 0 ? `
          <div class="section">
            <h2 class="section-title">Formação Acadêmica</h2>
            ${data.education.map(edu => `
              <div class="item">
                <h3>${edu.degree}</h3>
                <p>${edu.institution}</p>
                <p><em>${edu.period}</em></p>
              </div>
            `).join('')}
          </div>
        ` : ''}

        ${data.skills.length > 0 ? `
          <div class="section">
            <h2 class="section-title">Habilidades</h2>
            <ul>
              ${data.skills.map(skill => `<li>${skill}</li>`).join('')}
            </ul>
          </div>
        ` : ''}
      </body>
    </html>
  `;
}

function generateShareableText(data: ResumeData): string {
  return `
${data.name}
${data.email} | ${data.phone}

${data.summary}

Experiência Profissional:
${data.experience.map(exp => `
${exp.title}
${exp.company}
${exp.period}
${exp.description}
`).join('\n')}

Formação:
${data.education.map(edu => `
${edu.degree}
${edu.institution}
${edu.period}
`).join('\n')}

Habilidades:
${data.skills.join(', ')}
  `.trim();
}

async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    alert('Currículo copiado para a área de transferência!');
  } catch (error) {
    throw new Error('Não foi possível copiar para a área de transferência');
  }
}