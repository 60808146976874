import React from 'react';
import { Brain, Clock, Award } from 'lucide-react';
import { Test, TestResult } from './types';

interface TestCardProps extends Test {
  onClick: () => void;
  result?: TestResult;
}

export function TestCard({
  title,
  description,
  duration,
  difficulty,
  category,
  onClick,
  result,
}: TestCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold text-lg">{title}</h3>
          <p className="text-gray-600 text-sm mt-1">{description}</p>
        </div>
        <div className="h-10 w-10 rounded-full bg-blue-50 flex items-center justify-center">
          <Brain className="h-5 w-5 text-blue-600" />
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex items-center text-gray-600 text-sm">
          <Clock className="h-4 w-4 mr-1" />
          <span>{duration}</span>
        </div>
        <div className="flex items-center text-gray-600 text-sm">
          <Award className="h-4 w-4 mr-1" />
          <span>{difficulty}</span>
        </div>
      </div>

      {result ? (
        <div className="mb-4">
          <div className="flex justify-between text-sm mb-1">
            <span className="text-gray-600">Sua pontuação</span>
            <span className="font-medium text-blue-600">{result.score}%</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-full bg-blue-600 rounded-full"
              style={{ width: `${result.score}%` }}
            />
          </div>
        </div>
      ) : null}
      
      <button
        onClick={onClick}
        className={`w-full py-2 rounded-lg text-center ${
          result
            ? 'bg-green-50 text-green-700 hover:bg-green-100'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
      >
        {result ? 'Refazer Teste' : 'Iniciar Teste'}
      </button>
    </div>
  );
}