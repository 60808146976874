import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { TestResult } from '../components/dashboard/aptitude/types';

interface DashboardState {
  totalCourses: number;
  completedCourses: number;
  totalTests: number;
  completedTests: number;
  jobApplications: number;
  portfolioViews: number;
  averageScore: number;
  testResults: TestResult[];
  recentActivities: Array<{
    id: string;
    type: 'course' | 'test' | 'job' | 'portfolio';
    title: string;
    timestamp: Date;
    read: boolean;
  }>;
  addActivity: (activity: Omit<DashboardState['recentActivities'][0], 'id' | 'timestamp' | 'read'>) => void;
  updateTestScore: (result: TestResult) => void;
  markNotificationsAsRead: () => void;
  clearNotifications: () => void;
}

export const useDashboardStore = create<DashboardState>()(
  persist(
    (set, get) => ({
      totalCourses: 0,
      completedCourses: 0,
      totalTests: 0,
      completedTests: 0,
      jobApplications: 0,
      portfolioViews: 0,
      averageScore: 0,
      testResults: [],
      recentActivities: [],

      updateTestScore: (result) => {
        set((state) => {
          const newResults = [...state.testResults.filter(r => r.testId !== result.testId), result];
          const totalScore = newResults.reduce((acc, curr) => acc + curr.score, 0);
          const averageScore = Math.round(totalScore / newResults.length);
          
          return {
            testResults: newResults,
            completedTests: newResults.length,
            averageScore,
          };
        });
      },

      addActivity: (activity) =>
        set((state) => ({
          recentActivities: [
            {
              id: Math.random().toString(36).substr(2, 9),
              ...activity,
              timestamp: new Date(),
              read: false,
            },
            ...state.recentActivities.slice(0, 19),
          ],
        })),

      markNotificationsAsRead: () =>
        set((state) => ({
          recentActivities: state.recentActivities.map(activity => ({
            ...activity,
            read: true,
          })),
        })),

      clearNotifications: () =>
        set({ recentActivities: [] }),
    }),
    {
      name: 'dashboard-storage',
    }
  )
);