import React, { useState } from 'react';
import { FileText, Clock, Check, X, Download, Trash2 } from 'lucide-react';
import { Document } from '../../../services/documents/types';
import { formatTimeAgo } from '../../../utils/date';
import { downloadDocument } from '../../../services/documents/documentDownload';
import { deleteDocument } from '../../../services/documents/documentDelete';

interface DocumentCardProps {
  document: Document;
  onUpdate: () => void;
}

export function DocumentCard({ document, onUpdate }: DocumentCardProps) {
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDownload = async (url: string | undefined, prefix: string) => {
    if (!url) {
      alert('URL do documento não disponível');
      return;
    }

    setDownloading(true);
    try {
      await downloadDocument(url, `${prefix}_${document.title}.pdf`);
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Erro ao baixar documento');
    } finally {
      setDownloading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Tem certeza que deseja excluir este documento?')) {
      return;
    }

    setDeleting(true);
    try {
      const urls = [document.file_url];
      if (document.original_url) {
        urls.push(document.original_url);
      }
      
      await deleteDocument(document.id, urls);
      onUpdate();
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Erro ao excluir documento');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center space-x-3">
            <div className="h-10 w-10 bg-blue-100 rounded-lg flex items-center justify-center">
              <FileText className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <h3 className="font-semibold text-lg">{document.title}</h3>
              <p className="text-sm text-gray-500">
                {formatTimeAgo(new Date(document.created_at))}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className={`px-3 py-1 rounded-full text-sm flex items-center space-x-1 
              ${document.status === 'completed' ? 'bg-green-100 text-green-800' : 
                document.status === 'expired' ? 'bg-red-100 text-red-800' : 
                'bg-yellow-100 text-yellow-800'}`}
            >
              {document.status === 'completed' ? <Check className="h-4 w-4" /> :
               document.status === 'expired' ? <X className="h-4 w-4" /> :
               <Clock className="h-4 w-4" />}
              <span>{document.status.charAt(0).toUpperCase() + document.status.slice(1)}</span>
            </span>
            <button
              onClick={handleDelete}
              disabled={deleting}
              className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100"
              title="Excluir documento"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>

        {document.description && (
          <p className="text-gray-600 text-sm mb-4">{document.description}</p>
        )}

        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">Assinantes:</h4>
          <div className="space-y-1">
            {document.signers.map((signer, index) => (
              <div key={index} className="flex items-center justify-between text-sm">
                <span>{signer.name}</span>
                {signer.signed ? (
                  <span className="text-green-600 flex items-center">
                    <Check className="h-4 w-4 mr-1" />
                    Assinado
                  </span>
                ) : (
                  <span className="text-yellow-600 flex items-center">
                    <Clock className="h-4 w-4 mr-1" />
                    Pendente
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4 flex space-x-3">
          {document.original_url && (
            <button
              onClick={() => handleDownload(document.original_url, 'original')}
              disabled={downloading}
              className="flex-1 flex items-center justify-center space-x-2 bg-gray-100 text-gray-700 p-2 rounded-lg hover:bg-gray-200 disabled:opacity-50"
            >
              <Download className={`h-4 w-4 ${downloading ? 'animate-spin' : ''}`} />
              <span>{downloading ? 'Baixando...' : 'Original'}</span>
            </button>
          )}
          <button
            onClick={() => handleDownload(document.file_url, 'assinado')}
            disabled={downloading}
            className="flex-1 flex items-center justify-center space-x-2 bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            <Download className={`h-4 w-4 ${downloading ? 'animate-spin' : ''}`} />
            <span>{downloading ? 'Baixando...' : 'Assinado'}</span>
          </button>
        </div>
      </div>
    </div>
  );
}