import React, { useState, useEffect } from 'react';
import { ArrowLeft, Clock, AlertCircle } from 'lucide-react';
import { Test, Question } from './types';

interface QuizPlayerProps {
  test: Test;
  onComplete: (score: number, timeSpent: number, answers: any[]) => void;
  onBack: () => void;
}

export function QuizPlayer({ test, onComplete, onBack }: QuizPlayerProps) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<number[]>([]);
  const [timeSpent, setTimeSpent] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeSpent(prev => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleAnswer = (optionIndex: number) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = optionIndex;
    setAnswers(newAnswers);
    setShowExplanation(true);
  };

  const calculateScore = () => {
    let correct = 0;
    answers.forEach((answer, index) => {
      if (answer === test.questions[index].correctAnswer) {
        correct++;
      }
    });
    return Math.round((correct / test.questions.length) * 100);
  };

  const handleComplete = () => {
    const score = calculateScore();
    const answersDetails = answers.map((answer, index) => ({
      questionId: test.questions[index].id,
      correct: answer === test.questions[index].correctAnswer,
      answer,
    }));
    setIsCompleted(true);
    onComplete(score, timeSpent, answersDetails);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const question = test.questions[currentQuestion];
  const progress = ((currentQuestion + 1) / test.questions.length) * 100;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      {/* Header and progress bar */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <button onClick={onBack} className="p-2 hover:bg-gray-100 rounded-full">
            <ArrowLeft className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">
              Questão {currentQuestion + 1} de {test.questions.length}
            </span>
            <div className="flex items-center text-gray-600">
              <Clock className="h-4 w-4 mr-1" />
              <span>{formatTime(timeSpent)}</span>
            </div>
          </div>
        </div>
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-blue-600 rounded-full transition-all"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {/* Question */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-6">{question.text}</h3>
        <div className="space-y-4">
          {question.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(index)}
              disabled={showExplanation}
              className={`w-full p-4 text-left rounded-lg border transition-colors ${
                answers[currentQuestion] === index
                  ? showExplanation
                    ? index === question.correctAnswer
                      ? 'border-green-500 bg-green-50'
                      : 'border-red-500 bg-red-50'
                    : 'border-blue-500 bg-blue-50'
                  : 'border-gray-200 hover:border-blue-300'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* Explanation */}
      {showExplanation && question.explanation && (
        <div className="mb-6 p-4 bg-blue-50 rounded-lg border border-blue-200">
          <div className="flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5" />
            <div>
              <h4 className="font-medium text-blue-900">Explicação</h4>
              <p className="text-blue-800">{question.explanation}</p>
            </div>
          </div>
        </div>
      )}

      {/* Navigation */}
      <div className="flex justify-between">
        <button
          onClick={() => {
            setCurrentQuestion(prev => prev - 1);
            setShowExplanation(false);
          }}
          disabled={currentQuestion === 0}
          className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 disabled:opacity-50"
        >
          Anterior
        </button>
        {currentQuestion === test.questions.length - 1 ? (
          <button
            onClick={handleComplete}
            disabled={answers.length !== test.questions.length}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            Concluir
          </button>
        ) : (
          <button
            onClick={() => {
              setCurrentQuestion(prev => prev + 1);
              setShowExplanation(false);
            }}
            disabled={answers[currentQuestion] === undefined}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            Próxima
          </button>
        )}
      </div>
    </div>
  );
}