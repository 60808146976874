import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ThemeState {
  theme: 'light' | 'dark' | 'system';
  language: 'pt-BR' | 'en-US';
  setTheme: (theme: ThemeState['theme']) => void;
  setLanguage: (language: ThemeState['language']) => void;
}

export const useThemeStore = create<ThemeState>()(
  persist(
    (set) => ({
      theme: 'light',
      language: 'pt-BR',
      setTheme: (theme) => {
        set({ theme });
        applyTheme(theme);
      },
      setLanguage: (language) => {
        set({ language });
        document.documentElement.lang = language.toLowerCase();
      },
    }),
    {
      name: 'theme-storage',
    }
  )
);

function applyTheme(theme: ThemeState['theme']) {
  if (theme === 'system') {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    document.documentElement.classList.toggle('dark', systemTheme === 'dark');
  } else {
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }
}