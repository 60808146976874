import { CareerProfile } from './types';

export async function analyzeAnswers(answers: Record<number, number>): Promise<CareerProfile> {
  // Simula um delay para análise
  await new Promise(resolve => setTimeout(resolve, 1500));

  // Análise simplificada baseada nas respostas
  const traits = {
    analytical: 0,
    creative: 0,
    social: 0,
    practical: 0,
    investigative: 0
  };

  // Processa as respostas
  Object.entries(answers).forEach(([questionId, answer]) => {
    switch (answer) {
      case 0:
        traits.analytical += 1;
        traits.investigative += 0.5;
        break;
      case 1:
        traits.creative += 1;
        traits.investigative += 0.5;
        break;
      case 2:
        traits.social += 1;
        traits.creative += 0.5;
        break;
      case 3:
        traits.practical += 1;
        traits.analytical += 0.5;
        break;
    }
  });

  // Determina as áreas recomendadas baseado nos traços mais fortes
  const recommendedAreas = getRecommendedAreas(traits);
  const careers = getCareersForAreas(recommendedAreas);
  const courses = getRecommendedCourses(recommendedAreas);

  return {
    recommendedAreas,
    careers,
    recommendedCourses: courses,
    traits
  };
}

function getRecommendedAreas(traits: Record<string, number>): string[] {
  const sortedTraits = Object.entries(traits)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 3)
    .map(([trait]) => trait);

  const areaMapping: Record<string, string[]> = {
    analytical: ['Tecnologia', 'Ciências Exatas', 'Engenharia'],
    creative: ['Artes', 'Design', 'Comunicação'],
    social: ['Ciências Humanas', 'Educação', 'Saúde'],
    practical: ['Engenharia', 'Administração', 'Arquitetura'],
    investigative: ['Pesquisa', 'Ciências', 'Tecnologia']
  };

  return Array.from(new Set(
    sortedTraits.flatMap(trait => areaMapping[trait] || [])
  )).slice(0, 5);
}

function getCareersForAreas(areas: string[]): string[] {
  const careerMapping: Record<string, string[]> = {
    'Tecnologia': ['Desenvolvimento de Software', 'Análise de Dados', 'Segurança da Informação'],
    'Ciências Exatas': ['Matemática', 'Física', 'Estatística'],
    'Engenharia': ['Engenharia de Software', 'Engenharia Civil', 'Engenharia Mecânica'],
    'Artes': ['Design Digital', 'Direção de Arte', 'Produção Audiovisual'],
    'Design': ['UX/UI Design', 'Design Gráfico', 'Design de Produto'],
    'Comunicação': ['Marketing Digital', 'Jornalismo', 'Relações Públicas'],
    'Ciências Humanas': ['Psicologia', 'Sociologia', 'Antropologia'],
    'Educação': ['Docência', 'Pedagogia', 'Educação Especial'],
    'Saúde': ['Medicina', 'Enfermagem', 'Fisioterapia'],
    'Administração': ['Gestão de Projetos', 'Empreendedorismo', 'Consultoria'],
    'Arquitetura': ['Arquitetura', 'Urbanismo', 'Design de Interiores'],
    'Pesquisa': ['Pesquisa Acadêmica', 'Desenvolvimento Científico', 'Inovação'],
    'Ciências': ['Biotecnologia', 'Química', 'Biologia']
  };

  return Array.from(new Set(
    areas.flatMap(area => careerMapping[area] || [])
  )).slice(0, 8);
}

function getRecommendedCourses(areas: string[]): string[] {
  const courseMapping: Record<string, string[]> = {
    'Tecnologia': ['Ciência da Computação', 'Análise e Desenvolvimento de Sistemas', 'Engenharia de Software'],
    'Ciências Exatas': ['Matemática Aplicada', 'Física Computacional', 'Estatística'],
    'Engenharia': ['Engenharia de Computação', 'Engenharia Civil', 'Engenharia de Produção'],
    'Artes': ['Design Digital', 'Artes Visuais', 'Produção Multimídia'],
    'Design': ['Design', 'Design de Interfaces', 'Design de Produtos'],
    'Comunicação': ['Publicidade e Propaganda', 'Jornalismo', 'Marketing'],
    'Ciências Humanas': ['Psicologia', 'Filosofia', 'Sociologia'],
    'Educação': ['Pedagogia', 'Licenciatura', 'Educação Física'],
    'Saúde': ['Medicina', 'Enfermagem', 'Nutrição'],
    'Administração': ['Administração de Empresas', 'Gestão de Projetos', 'Economia'],
    'Arquitetura': ['Arquitetura e Urbanismo', 'Design de Interiores', 'Paisagismo'],
    'Pesquisa': ['Mestrado em áreas específicas', 'Doutorado', 'Pós-graduação em Pesquisa'],
    'Ciências': ['Biologia', 'Química', 'Biotecnologia']
  };

  return Array.from(new Set(
    areas.flatMap(area => courseMapping[area] || [])
  )).slice(0, 6);
}