import React from 'react';
import { Bell, FileText, Briefcase, Brain, Code, X } from 'lucide-react';
import { useDashboardStore } from '../../../store/dashboardStore';
import { formatTimeAgo } from '../../../utils/date';

const activityIcons = {
  course: FileText,
  test: Brain,
  job: Briefcase,
  portfolio: Code,
};

export function NotificationDropdown({ onClose }: { onClose: () => void }) {
  const activities = useDashboardStore((state) => state.recentActivities);
  const clearNotifications = useDashboardStore((state) => state.clearNotifications);

  return (
    <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-1 z-50">
      <div className="flex items-center justify-between px-4 py-2 border-b">
        <h3 className="font-medium">Notificações</h3>
        <button
          onClick={clearNotifications}
          className="text-sm text-blue-600 hover:text-blue-700"
        >
          Limpar todas
        </button>
      </div>

      <div className="max-h-96 overflow-y-auto">
        {activities.length > 0 ? (
          activities.map((activity) => {
            const Icon = activityIcons[activity.type];
            return (
              <div
                key={activity.id}
                className="px-4 py-3 hover:bg-gray-50 flex items-start space-x-3"
              >
                <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center text-blue-600 flex-shrink-0">
                  <Icon className="h-4 w-4" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm text-gray-900">{activity.title}</p>
                  <p className="text-xs text-gray-500">
                    {formatTimeAgo(new Date(activity.timestamp))}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="px-4 py-6 text-center text-gray-500">
            <Bell className="h-8 w-8 mx-auto mb-2 text-gray-400" />
            <p>Nenhuma notificação</p>
          </div>
        )}
      </div>
    </div>
  );
}