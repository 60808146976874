import { Test } from '../components/dashboard/aptitude/types';

export const AVAILABLE_TESTS: Test[] = [
  // Technical Tests
  {
    id: 'tech-1',
    title: 'Desenvolvimento Frontend',
    description: 'Avalie seus conhecimentos em HTML, CSS, JavaScript e React',
    duration: '45 minutos',
    category: 'technical',
    difficulty: 'Intermediário',
    questions: [
      {
        id: 1,
        text: 'Qual hook do React é usado para executar efeitos colaterais em componentes?',
        options: ['useEffect', 'useState', 'useMemo', 'useCallback'],
        correctAnswer: 0,
        explanation: 'useEffect é usado para lidar com efeitos colaterais como chamadas API e subscrições.'
      },
      {
        id: 2,
        text: 'Como centralizar um elemento usando Flexbox?',
        options: [
          'display: flex; justify-content: center; align-items: center;',
          'display: grid; place-items: center;',
          'position: absolute; top: 50%; left: 50%;',
          'margin: 0 auto;'
        ],
        correctAnswer: 0,
        explanation: 'Usando flexbox, podemos centralizar horizontalmente com justify-content e verticalmente com align-items.'
      },
      {
        id: 3,
        text: 'Qual método é usado para prevenir o comportamento padrão de um evento em JavaScript?',
        options: ['event.preventDefault()', 'event.stopPropagation()', 'event.stop()', 'event.halt()'],
        correctAnswer: 0,
        explanation: 'preventDefault() impede que o navegador execute a ação padrão associada ao evento.'
      }
    ]
  },
  // Logic Tests
  {
    id: 'logic-1',
    title: 'Raciocínio Lógico Avançado',
    description: 'Teste suas habilidades de resolução de problemas complexos',
    duration: '60 minutos',
    category: 'logic',
    difficulty: 'Avançado',
    questions: [
      {
        id: 1,
        text: 'Em uma sequência, cada número é a soma dos dois anteriores: 2, 3, 5, 8, 13, ___. Qual é o próximo número?',
        options: ['18', '21', '23', '24'],
        correctAnswer: 1,
        explanation: 'Na sequência de Fibonacci, cada número é a soma dos dois anteriores. 13 + 8 = 21'
      },
      {
        id: 2,
        text: 'Se todos os Xs são Ys, e alguns Ys são Zs, qual das seguintes afirmações é necessariamente verdadeira?',
        options: [
          'Todos os Xs são Zs',
          'Alguns Xs podem ser Zs',
          'Nenhum X é Z',
          'Todos os Zs são Xs'
        ],
        correctAnswer: 1,
        explanation: 'Como todos os Xs são Ys e alguns Ys são Zs, então é possível que alguns Xs sejam Zs.'
      }
    ]
  },
  // Soft Skills
  {
    id: 'soft-1',
    title: 'Liderança e Gestão de Equipes',
    description: 'Avalie suas habilidades de liderança e gestão de pessoas',
    duration: '40 minutos',
    category: 'soft-skills',
    difficulty: 'Intermediário',
    questions: [
      {
        id: 1,
        text: 'Qual a melhor abordagem ao lidar com um conflito entre membros da equipe?',
        options: [
          'Ouvir ambos os lados e facilitar um diálogo construtivo',
          'Determinar quem está certo e tomar partido',
          'Ignorar o conflito esperando que se resolva naturalmente',
          'Transferir um dos membros para outra equipe'
        ],
        correctAnswer: 0,
        explanation: 'A mediação e o diálogo são essenciais para resolver conflitos de forma construtiva.'
      },
      {
        id: 2,
        text: 'Como motivar uma equipe durante um projeto desafiador?',
        options: [
          'Estabelecer metas claras e reconhecer conquistas intermediárias',
          'Aumentar a pressão para melhorar a produtividade',
          'Focar apenas no resultado final',
          'Evitar discussões sobre dificuldades'
        ],
        correctAnswer: 0,
        explanation: 'Metas claras e reconhecimento regular ajudam a manter a motivação da equipe.'
      }
    ]
  },
  // Language Tests
  {
    id: 'lang-1',
    title: 'Business English',
    description: 'Teste seu inglês em contexto profissional',
    duration: '50 minutos',
    category: 'language',
    difficulty: 'Avançado',
    questions: [
      {
        id: 1,
        text: 'Choose the most appropriate response to: "I\'m afraid we\'ll have to postpone the meeting."',
        options: [
          'That works for me. When would you like to reschedule?',
          'Why do you have fear?',
          'I don\'t like meetings anyway.',
          'OK, bye.'
        ],
        correctAnswer: 0,
        explanation: 'This response is professional and shows willingness to be flexible.'
      },
      {
        id: 2,
        text: 'Which phrase is most appropriate for a formal business email?',
        options: [
          'I look forward to hearing from you.',
          'Talk to you later!',
          'Catch you soon!',
          'Bye for now!'
        ],
        correctAnswer: 0,
        explanation: 'This is a standard formal closing for business correspondence.'
      }
    ]
  },
  // Problem Solving
  {
    id: 'prob-1',
    title: 'Resolução de Problemas',
    description: 'Avalie sua capacidade de resolver problemas complexos',
    duration: '55 minutos',
    category: 'logic',
    difficulty: 'Avançado',
    questions: [
      {
        id: 1,
        text: 'Uma empresa precisa otimizar sua logística. Qual seria a primeira etapa?',
        options: [
          'Analisar os dados atuais e identificar gargalos',
          'Implementar um novo sistema imediatamente',
          'Demitir funcionários ineficientes',
          'Aumentar o orçamento'
        ],
        correctAnswer: 0,
        explanation: 'A análise de dados é fundamental para identificar problemas e oportunidades de melhoria.'
      }
    ]
  }
];