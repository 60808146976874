import React from 'react';
import { ResumeData } from '../types';
import { Mail, Phone, MapPin } from 'lucide-react';

export function ModernTemplate({ data }: { data: ResumeData }) {
  return (
    <div className="p-8 font-sans bg-white">
      {/* Header with accent color */}
      <div className="border-l-4 border-blue-600 pl-6 mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">{data.name}</h1>
        <div className="flex flex-wrap gap-4 text-gray-600">
          {data.email && (
            <div className="flex items-center gap-1">
              <Mail className="h-4 w-4" />
              <span>{data.email}</span>
            </div>
          )}
          {data.phone && (
            <div className="flex items-center gap-1">
              <Phone className="h-4 w-4" />
              <span>{data.phone}</span>
            </div>
          )}
        </div>
      </div>

      {/* Summary with highlight */}
      {data.summary && (
        <div className="mb-8 bg-gray-50 p-4 rounded-lg border-l-4 border-blue-600">
          <p className="text-gray-700 leading-relaxed">{data.summary}</p>
        </div>
      )}

      {/* Experience */}
      {data.experience.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Experiência Profissional</h2>
          <div className="space-y-6">
            {data.experience.map((exp, index) => (
              <div key={index} className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-2 before:w-2 before:h-2 before:bg-blue-600 before:rounded-full">
                <h3 className="font-bold text-xl text-gray-900">{exp.title}</h3>
                <div className="text-blue-600 font-medium">{exp.company}</div>
                <div className="text-gray-500 text-sm mb-2">{exp.period}</div>
                <p className="text-gray-700">{exp.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Education */}
      {data.education.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Formação Acadêmica</h2>
          <div className="grid gap-4">
            {data.education.map((edu, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-bold text-lg text-gray-900">{edu.degree}</h3>
                <div className="text-gray-700">{edu.institution}</div>
                <div className="text-gray-500 text-sm">{edu.period}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Skills */}
      {data.skills.length > 0 && (
        <div>
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Habilidades</h2>
          <div className="flex flex-wrap gap-2">
            {data.skills.map((skill, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm font-medium"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}