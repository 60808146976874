import React, { useState } from 'react';
import { Plus, Save } from 'lucide-react';
import { ImageUpload } from '../../shared/ImageUpload';
import { useAuth } from '../../../hooks/useAuth';
import { supabase } from '../../../lib/supabase';
import { ProjectFormData } from './types';

const initialFormData: ProjectFormData = {
  title: '',
  description: '',
  image: '',
  technologies: [],
  link: '',
  github_link: '',
  category: 'web',
};

interface PortfolioEditorProps {
  onSave: () => void;
  onCancel: () => void;
}

export function PortfolioEditor({ onSave, onCancel }: PortfolioEditorProps) {
  const [formData, setFormData] = useState<ProjectFormData>(initialFormData);
  const [technology, setTechnology] = useState('');
  const { user } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      const { error } = await supabase.from('portfolio_projects').insert({
        user_id: user.id,
        ...formData,
        created_at: new Date().toISOString(),
      });

      if (error) throw error;
      onSave();
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const handleAddTechnology = () => {
    if (technology.trim()) {
      setFormData(prev => ({
        ...prev,
        technologies: [...prev.technologies, technology.trim()],
      }));
      setTechnology('');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-semibold mb-6">Adicionar Novo Projeto</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Imagem do Projeto
          </label>
          <ImageUpload
            currentImage={formData.image}
            onUpload={(url) => setFormData(prev => ({ ...prev, image: url }))}
            className="w-full h-48 rounded-lg"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Título
          </label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Descrição
          </label>
          <textarea
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            rows={4}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tecnologias
          </label>
          <div className="flex gap-2 mb-2">
            <input
              type="text"
              value={technology}
              onChange={(e) => setTechnology(e.target.value)}
              className="flex-1 p-2 border rounded-lg"
              placeholder="Ex: React, Node.js, etc."
            />
            <button
              type="button"
              onClick={handleAddTechnology}
              className="p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {formData.technologies.map((tech, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Link do Projeto
            </label>
            <input
              type="url"
              value={formData.link}
              onChange={(e) => setFormData(prev => ({ ...prev, link: e.target.value }))}
              className="w-full p-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Link do GitHub (opcional)
            </label>
            <input
              type="url"
              value={formData.github_link}
              onChange={(e) => setFormData(prev => ({ ...prev, github_link: e.target.value }))}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Categoria
          </label>
          <select
            value={formData.category}
            onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
            className="w-full p-2 border rounded-lg"
          >
            <option value="web">Desenvolvimento Web</option>
            <option value="mobile">Desenvolvimento Mobile</option>
            <option value="desktop">Aplicações Desktop</option>
            <option value="design">Design</option>
            <option value="other">Outros</option>
          </select>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <Save className="h-4 w-4 mr-2 inline-block" />
            Salvar Projeto
          </button>
        </div>
      </form>
    </div>
  );
}