import React, { useState } from 'react';

interface JobFiltersProps {
  onSearch: () => void;
}

export function JobFilters({ onSearch }: JobFiltersProps) {
  const [filters, setFilters] = useState({
    type: 'all',
    location: 'all',
  });

  return (
    <div className="flex gap-4">
      <select
        value={filters.type}
        onChange={(e) => setFilters({ ...filters, type: e.target.value })}
        className="px-4 py-2 border rounded-lg"
      >
        <option value="all">Tipo</option>
        <option value="full-time">Tempo Integral</option>
        <option value="part-time">Meio Período</option>
        <option value="contract">Contrato</option>
      </select>
      <select
        value={filters.location}
        onChange={(e) => setFilters({ ...filters, location: e.target.value })}
        className="px-4 py-2 border rounded-lg"
      >
        <option value="all">Local</option>
        <option value="remote">Remoto</option>
        <option value="on-site">Presencial</option>
        <option value="hybrid">Híbrido</option>
      </select>
      <button
        onClick={onSearch}
        className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
      >
        Buscar
      </button>
    </div>
  );
}