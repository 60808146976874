import React, { useState } from 'react';
import { X, Loader } from 'lucide-react';
import { paymentService } from '../../services/payment/paymentService';

interface PaymentModalProps {
  planId: string;
  onClose: () => void;
}

export function PaymentModal({ planId, onClose }: PaymentModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePayment = async () => {
    setLoading(true);
    setError(null);

    try {
      const session = await paymentService.createCheckoutSession(planId);
      if (session?.url) {
        window.location.href = session.url;
      } else {
        throw new Error('Invalid checkout session');
      }
    } catch (err) {
      setError('Erro ao processar pagamento. Por favor, tente novamente.');
      console.error('Error creating checkout session:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Confirmar Assinatura</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {error && (
          <div className="bg-red-50 text-red-600 p-3 rounded-lg mb-4">
            {error}
          </div>
        )}

        <p className="text-gray-600 mb-6">
          Você será redirecionado para nossa página de pagamento segura para completar sua assinatura.
        </p>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            disabled={loading}
          >
            Cancelar
          </button>
          <button
            onClick={handlePayment}
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center"
          >
            {loading ? (
              <>
                <Loader className="animate-spin h-4 w-4 mr-2" />
                Processando...
              </>
            ) : (
              'Continuar para Pagamento'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}