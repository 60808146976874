import React, { useState, useEffect } from 'react';
import { Search, Briefcase } from 'lucide-react';
import { fetchJobs } from '../../../services/jobsApi';
import { JobCard } from './JobCard';
import { JobFilters } from './JobFilters';
import { useDashboardStore } from '../../../store/dashboardStore';
import { JobType } from '../../../types/job';

export function JobSearch() {
  const [jobs, setJobs] = useState<JobType[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const addActivity = useDashboardStore((state) => state.addActivity);

  useEffect(() => {
    loadJobs();
  }, []);

  const loadJobs = async () => {
    setLoading(true);
    try {
      const data = await fetchJobs(search);
      setJobs(data);
    } catch (error) {
      console.error('Error loading jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApply = (job: JobType) => {
    addActivity({
      type: 'job',
      title: `Candidatou-se para: ${job.title} em ${job.company}`,
    });
    window.open(job.url, '_blank');
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Buscar vagas..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
            />
          </div>
          <JobFilters onSearch={loadJobs} />
        </div>
      </div>

      {loading ? (
        <div className="text-center py-12">
          <Briefcase className="h-8 w-8 animate-spin text-blue-600 mx-auto" />
          <p className="mt-2 text-gray-600">Carregando vagas...</p>
        </div>
      ) : jobs.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {jobs.map((job) => (
            <JobCard
              key={job.id}
              {...job}
              onApply={() => handleApply(job)}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <Briefcase className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Nenhuma vaga encontrada
          </h3>
          <p className="text-gray-600">
            Tente ajustar seus filtros ou fazer uma nova busca
          </p>
        </div>
      )}
    </div>
  );
}