import React, { useState } from 'react';
import { FileText, Users, MessageSquare, Star } from 'lucide-react';
import { Sidebar } from '../components/dashboard/Sidebar';
import { DashboardHeader } from '../components/dashboard/DashboardHeader';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { StatCard } from '../components/dashboard/StatCard';
import { ActivityFeed } from '../components/dashboard/ActivityFeed';
import { ProgressOverview } from '../components/dashboard/ProgressOverview';
import { ResumeBuilder } from '../components/resume/ResumeBuilder';
import { ChatInterface } from '../components/chat/ChatInterface';
import { JobSearch } from '../components/dashboard/jobs/JobSearch';
import { CourseList } from '../components/dashboard/courses/CourseList';
import { PortfolioList } from '../components/dashboard/portfolio/PortfolioList';
import { TestList } from '../components/dashboard/aptitude/TestList';
import { SignatureList } from '../components/dashboard/signatures/SignatureList';
import { UserSettings } from '../components/dashboard/settings/UserSettings';
import { VocationalTest } from '../components/dashboard/vocational/VocationalTest';
import { useDashboardStore } from '../store/dashboardStore';

export function Dashboard() {
  const [activePage, setActivePage] = useState('dashboard');
  const {
    completedCourses,
    totalCourses,
    jobApplications,
    portfolioViews,
    averageScore,
  } = useDashboardStore();

  const renderContent = () => {
    switch (activePage) {
      case 'resumes':
        return (
          <DashboardLayout title="Currículos">
            <ResumeBuilder />
          </DashboardLayout>
        );
      case 'chat':
        return (
          <DashboardLayout>
            <ChatInterface />
          </DashboardLayout>
        );
      case 'courses':
        return (
          <DashboardLayout title="Cursos">
            <CourseList />
          </DashboardLayout>
        );
      case 'jobs':
        return (
          <DashboardLayout title="Vagas">
            <JobSearch />
          </DashboardLayout>
        );
      case 'portfolio':
        return (
          <DashboardLayout>
            <PortfolioList />
          </DashboardLayout>
        );
      case 'tests':
        return (
          <DashboardLayout title="Testes de Aptidão">
            <TestList />
          </DashboardLayout>
        );
      case 'vocational':
        return (
          <DashboardLayout title="Teste Vocacional">
            <VocationalTest />
          </DashboardLayout>
        );
      case 'signatures':
        return (
          <DashboardLayout>
            <SignatureList />
          </DashboardLayout>
        );
      case 'settings':
        return <UserSettings />;
      default:
        return (
          <DashboardLayout title="Dashboard">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
              <StatCard
                title="Cursos Concluídos"
                value={`${completedCourses}/${totalCourses}`}
                change="+20%"
                trend="up"
                icon={FileText}
              />
              <StatCard
                title="Candidaturas"
                value={jobApplications}
                change="+15%"
                trend="up"
                icon={Users}
              />
              <StatCard
                title="Visualizações do Portfólio"
                value={portfolioViews}
                change="+35%"
                trend="up"
                icon={MessageSquare}
              />
              <StatCard
                title="Média nos Testes"
                value={`${averageScore}%`}
                change="+5%"
                trend="up"
                icon={Star}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
              <ProgressOverview />
              <ActivityFeed />
            </div>
          </DashboardLayout>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar activePage={activePage} onPageChange={setActivePage} />
      
      <div className="lg:ml-64">
        <DashboardHeader />
        <main className="pt-16">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}