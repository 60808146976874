import { supabase } from '../lib/supabase';

interface ChatMessage {
  id: string;
  message: string;
  response: string;
  created_at: string;
}

export async function saveChatHistory(message: string, response: string): Promise<void> {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return;

  try {
    const { error } = await supabase
      .from('chat_history')
      .insert({
        user_id: user.id,
        message,
        response
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error saving chat history:', error);
  }
}

export async function getChatHistory(): Promise<ChatMessage[]> {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return [];

  try {
    // Calculate timestamp for 24 hours ago
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const { data, error } = await supabase
      .from('chat_history')
      .select('id, message, response, created_at')
      .eq('user_id', user.id)
      .gte('created_at', twentyFourHoursAgo.toISOString())
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching chat history:', error);
    return [];
  }
}

export async function cleanupOldMessages(): Promise<void> {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return;

  try {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const { error } = await supabase
      .from('chat_history')
      .delete()
      .eq('user_id', user.id)
      .lt('created_at', twentyFourHoursAgo.toISOString());

    if (error) throw error;
  } catch (error) {
    console.error('Error cleaning up old messages:', error);
  }
}

export async function getSuggestedQuestions(): Promise<string[]> {
  try {
    const { data, error } = await supabase
      .from('suggested_questions')
      .select('question')
      .limit(5);

    if (error) throw error;
    return data?.map(q => q.question) || [];
  } catch (error) {
    console.error('Error fetching suggested questions:', error);
    return [];
  }
}