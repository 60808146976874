import React from 'react';
import { BarChart, Clock, Award, Brain } from 'lucide-react';
import { TestResult } from './types';

interface TestPerformanceProps {
  results: TestResult[];
}

export function TestPerformance({ results }: TestPerformanceProps) {
  const averageScore = results.length
    ? results.reduce((acc, curr) => acc + curr.score, 0) / results.length
    : 0;

  const totalTests = results.length;
  const averageTime = results.length
    ? results.reduce((acc, curr) => acc + curr.timeSpent, 0) / results.length
    : 0;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-semibold mb-6">Seu Desempenho</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <span className="text-blue-600 font-medium">Média Geral</span>
            <Award className="h-5 w-5 text-blue-600" />
          </div>
          <p className="text-3xl font-bold text-blue-600">{averageScore.toFixed(1)}%</p>
        </div>

        <div className="bg-green-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <span className="text-green-600 font-medium">Testes Realizados</span>
            <Brain className="h-5 w-5 text-green-600" />
          </div>
          <p className="text-3xl font-bold text-green-600">{totalTests}</p>
        </div>

        <div className="bg-purple-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <span className="text-purple-600 font-medium">Tempo Médio</span>
            <Clock className="h-5 w-5 text-purple-600" />
          </div>
          <p className="text-3xl font-bold text-purple-600">
            {Math.round(averageTime / 60)}min
          </p>
        </div>
      </div>

      {results.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-4">Histórico de Testes</h3>
          <div className="space-y-4">
            {results.map((result) => (
              <div key={result.testId} className="bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-medium">Teste #{result.testId}</p>
                    <p className="text-sm text-gray-600">
                      {new Date(result.completedAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-lg font-semibold text-blue-600">
                      {result.score}%
                    </p>
                    <p className="text-sm text-gray-600">
                      {Math.round(result.timeSpent / 60)}min
                    </p>
                  </div>
                </div>
                <div className="mt-2 h-2 bg-gray-200 rounded-full">
                  <div
                    className="h-full bg-blue-600 rounded-full"
                    style={{ width: `${result.score}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}