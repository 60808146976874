import React from 'react';
import { ResumeData } from '../types';

export function MinimalTemplate({ data }: { data: ResumeData }) {
  return (
    <div className="p-8 font-sans bg-white">
      {/* Minimal Header */}
      <div className="text-center mb-12">
        <h1 className="text-5xl font-light tracking-wide text-gray-900 mb-4">{data.name}</h1>
        <div className="flex justify-center gap-6 text-gray-600 text-sm">
          {data.email && <span>{data.email}</span>}
          {data.phone && <span>{data.phone}</span>}
        </div>
      </div>

      {/* Summary */}
      {data.summary && (
        <div className="mb-12 max-w-2xl mx-auto text-center">
          <p className="text-gray-700 leading-relaxed italic">{data.summary}</p>
        </div>
      )}

      {/* Experience */}
      {data.experience.length > 0 && (
        <div className="mb-12">
          <h2 className="text-2xl font-light uppercase tracking-widest text-center mb-8">
            Experiência
          </h2>
          <div className="space-y-8">
            {data.experience.map((exp, index) => (
              <div key={index} className="grid grid-cols-[1fr_2px_2fr] gap-8">
                <div className="text-right">
                  <div className="font-medium text-gray-900">{exp.company}</div>
                  <div className="text-gray-500 text-sm">{exp.period}</div>
                </div>
                <div className="bg-gray-200 justify-self-center"></div>
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">{exp.title}</h3>
                  <p className="text-gray-600 text-sm">{exp.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Education */}
      {data.education.length > 0 && (
        <div className="mb-12">
          <h2 className="text-2xl font-light uppercase tracking-widest text-center mb-8">
            Formação
          </h2>
          <div className="grid gap-6 max-w-2xl mx-auto">
            {data.education.map((edu, index) => (
              <div key={index} className="text-center">
                <h3 className="font-medium text-gray-900">{edu.degree}</h3>
                <div className="text-gray-600">{edu.institution}</div>
                <div className="text-gray-500 text-sm">{edu.period}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Skills */}
      {data.skills.length > 0 && (
        <div>
          <h2 className="text-2xl font-light uppercase tracking-widest text-center mb-8">
            Habilidades
          </h2>
          <div className="flex flex-wrap justify-center gap-x-6 gap-y-2 max-w-2xl mx-auto">
            {data.skills.map((skill, index) => (
              <span key={index} className="text-gray-700">
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}