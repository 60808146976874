import React from 'react';
import { Question } from './types';

interface VocationalQuestionProps {
  question: Question;
  onAnswer: (questionId: number, value: number) => void;
  currentAnswer?: number;
}

export function VocationalQuestion({ question, onAnswer, currentAnswer }: VocationalQuestionProps) {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900">{question.text}</h3>
      <div className="space-y-3">
        {question.options.map((option, index) => (
          <button
            key={index}
            onClick={() => onAnswer(question.id, index)}
            className={`w-full text-left p-4 rounded-lg border transition-all ${
              currentAnswer === index
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:border-blue-300'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}